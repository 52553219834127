import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Divider,
  HStack,
  PinInput,
  PinInputField,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useJoinGroup, useVerifyGroup } from 'api/group';
import { GroupDto } from 'proxy';

import GroupCard from '../../components/Group/GroupCard';

interface IRouteParams {
  code?: string;
}

const JoinGroupContent = () => {
  const [invitationCode, setInvitationCode] = useState<string>('');
  const { code } = useParams<IRouteParams>();
  const { t } = useTranslation();
  const history = useHistory();
  const toast = useToast();

  const { data, mutate, isLoading, error } = useVerifyGroup();

  const joinGroupMutation = useJoinGroup();

  useEffect(() => {
    if (code) {
      setInvitationCode(code);
    }
  }, [code]);

  const handleChange = (value: string) => {
    setInvitationCode(value);
  };

  const handleVerify = () => {
    mutate(invitationCode);
  };

  const handleSubmit = () => {
    joinGroupMutation.mutate(invitationCode, {
      onSuccess: (data: GroupDto) => history.push(`/groups/${data.id}`),
      onError: (err) => {
        if (err === 'P2002') {
          toast({
            position: 'top',
            title: 'Already a member',
            description: "You're already a member of this group.",
            status: 'warning',
            duration: 10000,
            isClosable: true,
          });
        } else {
          toast({
            position: 'top',
            title: 'Request failed',
            description: 'Server is not responding. Please try again later.',
            status: 'error',
            duration: 10000,
            isClosable: true,
          });
        }
      },
    });
  };

  return (
    <VStack spacing={8} mb='16'>
      <HStack>
        <PinInput defaultValue={code} onChange={handleChange}>
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
        </PinInput>
      </HStack>
      <Button
        variant='outline'
        colorScheme='brandgreen'
        onClick={handleVerify}
        isDisabled={invitationCode?.length < 6}
        w={{ base: '33vw', lg: '20vw', xl: '15vw' }}
        isLoading={isLoading}
      >
        {t('Verify')}
      </Button>
      {error === 'P2025' && (
        <Alert status='error' w={{ base: '90vw', lg: '33vw', xl: '25vw' }} rounded='2xl'>
          <AlertIcon />
          <Box flex={1}>
            <AlertTitle>{t('GroupNotFound')}</AlertTitle>
            <AlertDescription display='block'>
              {t('Verify404', { invitationCode })}
            </AlertDescription>
          </Box>
        </Alert>
      )}
      <Divider />
      <VStack>{data && <GroupCard group={data} />}</VStack>
      {data && data?.members?.length === data?.maxPlayerCount ? (
        <Alert status='warning' w={{ base: '90vw', lg: '33vw', xl: '25vw' }} rounded='2xl'>
          <AlertIcon />
          <Box flex={1}>
            <AlertTitle>{t('Group is full')}</AlertTitle>
            <AlertDescription display='block'>{t('Group full description')}</AlertDescription>
          </Box>
        </Alert>
      ) : (
        ''
      )}
      <Button
        bgColor='brandorange.300'
        _hover={{ bgColor: 'brandorange.400' }}
        _active={{ bgColor: 'brandorange.400' }}
        color='white'
        isDisabled={!data || data?.members?.length === data?.maxPlayerCount}
        w={{ base: '90vw', lg: '33vw', xl: '25vw' }}
        onClick={handleSubmit}
      >
        {t('Join group')}
      </Button>
    </VStack>
  );
};

export default JoinGroupContent;
