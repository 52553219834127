import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import CreateGroupContent from './CreateGroupContent';

const CreateGroup = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    onOpen();
    return () => onClose();
  }, [onOpen, onClose]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} size='full'>
      <ModalOverlay />
      <ModalContent m='0'>
        <ModalHeader textAlign='center'>{t('Create group')}</ModalHeader>
        <ModalCloseButton onClick={() => history.goBack()} />
        <ModalBody>
          <CreateGroupContent />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateGroup;
