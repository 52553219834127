import {
  Center,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { RiQrCodeLine } from 'react-icons/ri';

type QRModalProps = {
  inviteLink: string;
};

const QrModal = ({ inviteLink }: QRModalProps) => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <IconButton
        name='qr'
        size='lg'
        colorScheme='brandblack'
        aria-label='Share QR code'
        icon={<RiQrCodeLine />}
        onClick={onOpen}
      />
      <Modal onClose={onClose} isOpen={isOpen} size='full'>
        <ModalOverlay zIndex={999} />
        <ModalContent m='0'>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody className='qr-code__background'>
            <VStack height='100%' justify='center' align='center'>
              <Heading
                textAlign='center'
                mb='8'
                bgColor='white'
                w={{ base: '90vw', md: '50vw', lg: '33vw', xl: '25vw' }}
              >
                {t('Scan and join your gourmet friends')} <br />
                👨🏻‍🍳 👩🏽‍🍳
              </Heading>
              <Center
                p='4'
                bgColor='white'
                w={{ base: '90vw', md: '50vw', lg: '33vw', xl: '25vw' }}
              >
                <QRCode value={inviteLink} size={256} />
              </Center>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default QrModal;
