import { useMutation } from '@tanstack/react-query';

import { getBackendAPI } from 'proxy';

function startGame(request: { groupId: string; gameId: string }) {
  return getBackendAPI().gameControllerStartGame(request.groupId, request.gameId);
}

export function useStartGame() {
  return useMutation(startGame);
}
