import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useCreateGroup } from 'api/group';
import SelectCityModal from 'components/Group/SelectCityModal';
import { GroupDto, GroupRequest } from 'proxy';

export type TGroupResponse = {
  data: GroupDto;
};

const CreateGroupContent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<GroupRequest>();
  const history = useHistory();
  const toast = useToast();
  const { t } = useTranslation();

  const createGroupMutation = useCreateGroup();

  const onSubmit = (newGroup: GroupRequest) => {
    newGroup.ratingDeadline = 24;
    createGroupMutation.mutate(newGroup, {
      onSuccess: (data: GroupDto) => history.push(`${data.id}`),
      onError: () => {
        toast({
          position: 'top',
          title: 'Request failed',
          description: 'Server is not responding. Please try again later.',
          status: 'error',
          duration: 10000,
          isClosable: true,
        });
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Center>
        <VStack spacing={8} w={{ base: '90vw', lg: '33vw', xl: '25vw' }}>
          <FormControl isInvalid={errors.name ? true : false}>
            <FormLabel htmlFor='name'>{t('Group name')}</FormLabel>
            <Input id='name' {...register('name', { required: true, maxLength: 40 })} />
            <FormErrorMessage>{errors.name && t('Please enter a group name')}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.city ? true : false}>
            <FormLabel htmlFor='city'>{t('City')}</FormLabel>
            <HStack>
              <Input
                readOnly
                id='city'
                placeholder={t('Select your city')}
                {...register('city', { required: true, maxLength: 50 })}
              />
              <SelectCityModal setCity={setValue} />
            </HStack>
            <FormErrorMessage>{errors.city && t('Please enter a valid city')}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors?.maxPlayerCount}>
            <FormLabel htmlFor='maxPlayerCount'>{t('Number of players')}</FormLabel>
            <NumberInput min={2} max={24} isRequired>
              <NumberInputField
                {...register('maxPlayerCount', {
                  required: true,
                  min: 2,
                  max: 12,
                })}
              />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <FormErrorMessage>
              {errors?.maxPlayerCount && t('Please enter number of players')}
            </FormErrorMessage>
          </FormControl>
          {/* <FormControl>
            <FormLabel htmlFor='ratingDeadline'>
              {t('Rating deadline')}{' '}
              <Popover placement='top'>
                <PopoverTrigger>
                  <IconButton
                    isRound
                    variant='ghost'
                    aria-label='Search database'
                    icon={<RiQuestionLine />}
                    fontSize='1.5rem'
                  />
                </PopoverTrigger>
                <PopoverContent border='3px solid' borderColor='brandblue.700'>
                  <PopoverArrow />
                  <PopoverBody>
                    {t(
                      'The rating deadline starts from the time set in the meeting point. After expiration, it is no longer possible to rate.'
                    )}
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </FormLabel>
            <NumberInput>
              <NumberInputField
                {...register('ratingDeadline', {
                  required: true,
                  min: 6,
                  max: 24,
                })}
              />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <FormErrorMessage>
              {errors?.ratingDeadline && t('Please enter rating deadline')}
            </FormErrorMessage>
          </FormControl> */}
          <Button
            color='white'
            bgColor='brandorange.300'
            _hover={{ bgColor: 'brandorange.400' }}
            _active={{ bgColor: 'brandorange.400' }}
            w={{ base: '90vw', lg: '33vw', xl: '25vw' }}
            isLoading={createGroupMutation.isLoading}
            type='submit'
          >
            {t('Create group')}
          </Button>
        </VStack>
      </Center>
    </form>
  );
};

export default CreateGroupContent;
