import { atom } from 'recoil';

export const groupState = atom({
  key: 'groupState',
  default: {
    groupId: '',
    gameId: '',
    roundId: '',
  },
});

export const ratingState = atom({
  key: 'ratingState',
  default: {
    currentIndex: 0,
    rating1: 5,
    rating2: 5,
    rating3: 5,
    rating4: 5,
  },
});

export const userState = atom({
  key: 'userState',
  default: {
    userLanguage: 'de_DE',
    yelpLanguage: 'de_DE',
  },
});
