import {
  VStack,
  HStack,
  Heading,
  IconButton,
  Box,
  Skeleton,
  Accordion,
  Image,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { HiRefresh } from 'react-icons/hi';

import { useResults } from 'api/result';
import { Empty } from 'assets/svg';
import Errors from 'components/Errors';
import { queryClient, queryKeys } from 'lib/react-query';

import ResultItem from './ResultItem';

const Results = () => {
  const { t } = useTranslation();
  const { isLoading, data, error } = useResults();

  if (error) {
    return <Errors onRefetch={() => queryClient.invalidateQueries(queryKeys.results.lists())} />;
  }

  return (
    <VStack>
      <HStack w={{ base: '90vw', lg: '43vw', xl: '35vw' }}>
        <Heading as='h4' size='lg' textAlign='left' w={{ base: '90vw', lg: '43vw', xl: '35vw' }}>
          {t('Your results')}
        </Heading>
        <IconButton
          aria-label='Refetch results'
          icon={<HiRefresh fontSize='24' />}
          onClick={() => queryClient.invalidateQueries(queryKeys.results.lists())}
        />
      </HStack>
      <Box>
        {data?.length === 0 ? (
          <VStack>
            <Image
              src={Empty}
              w={{ base: '66vw', sm: '33vw', lg: '20vw', xl: '23vw' }}
              alt='No data'
            />
            <Heading>{t('No data')}</Heading>
            <Text>{t('Finish your game to see results.')}</Text>
          </VStack>
        ) : (
          <Accordion spacing='6' mb='16' allowMultiple>
            {data?.map((result) => (
              <Skeleton key={result?.gameId} isLoaded={!isLoading} rounded='2xl'>
                <ResultItem result={result} />
              </Skeleton>
            ))}
          </Accordion>
        )}
      </Box>
    </VStack>
  );
};

export default Results;
