import { useAuth0 } from '@auth0/auth0-react';
import {
  VStack,
  HStack,
  Image,
  Avatar,
  Heading,
  Button,
  Icon,
  Divider,
  Center,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  RiAccountCircleLine,
  RiSettings3Line,
  RiQuestionLine,
  RiInformationLine,
  RiLock2Line,
  RiFilePaper2Line,
  RiStarSFill,
} from 'react-icons/ri';

import { BmcButton } from 'assets/svg';

import packageJson from '../../../package.json';

type TAccountProps = {
  setNav: React.Dispatch<React.SetStateAction<string>>;
};

const ProfileOverview = ({ setNav }: TAccountProps) => {
  const { user, logout } = useAuth0();
  const { t } = useTranslation();

  return (
    <>
      <VStack align='start' w={{ base: '90vw', lg: '33vw', xl: '25vw' }}>
        <HStack spacing='4' mb='4'>
          <Avatar size='lg' bg='brandgreen.500' name={user?.name} src={user?.picture} />
          <Heading size='lg' wordBreak='break-word'>
            {user?.name}
          </Heading>
        </HStack>
        <Button
          aria-label='Account'
          variant='ghost'
          leftIcon={<Icon as={RiAccountCircleLine} boxSize={6} />}
          onClick={() => setNav('account')}
        >
          {t('My account')}
        </Button>
        <Button
          aria-label='Settings'
          variant='ghost'
          leftIcon={<Icon as={RiSettings3Line} boxSize={6} />}
        >
          {t('Settings')}
        </Button>
        <Divider />
        <Button
          aria-label='Help'
          variant='ghost'
          leftIcon={<Icon as={RiQuestionLine} boxSize={6} />}
        >
          {t('Help')}
        </Button>
        <Button
          aria-label='Imprint'
          variant='ghost'
          leftIcon={<Icon as={RiInformationLine} boxSize={6} />}
        >
          {t('Imprint')}
        </Button>
        <Button
          aria-label='Privacy'
          variant='ghost'
          leftIcon={<Icon as={RiLock2Line} boxSize={6} />}
        >
          {t('Privacy')}
        </Button>
        <Button
          aria-label='Terms of Use'
          variant='ghost'
          leftIcon={<Icon as={RiFilePaper2Line} boxSize={6} />}
        >
          {t('Terms of Use')}
        </Button>
        <Divider />
        <Button
          aria-label='Feedback'
          variant='ghost'
          leftIcon={<Icon as={RiStarSFill} color='yellow.300' boxSize={6} />}
        >
          Feedback
        </Button>
        <Image
          pos='relative'
          left='1rem'
          w='10rem'
          mb='0.5rem'
          src={BmcButton}
          alt='Buy me a coffee'
          _hover={{ cursor: 'pointer' }}
          onClick={() => window.open('https://www.buymeacoffee.com/gourmetgames')}
        />
      </VStack>
      <VStack spacing='0' fontSize='xs'>
        <Text>
          {t('Logged in as')} {user?.email}
        </Text>
        <Text>Version {packageJson.version}</Text>
      </VStack>
      <Center>
        <Button
          onClick={() => logout({ logoutParams: { returnTo: `${window.location.origin}` } })}
          variant='outline'
          colorScheme='red'
          mt='4'
        >
          {t('Logout')}
        </Button>
      </Center>
    </>
  );
};

export default ProfileOverview;
