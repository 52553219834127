import { Heading, HStack, IconButton } from '@chakra-ui/react';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
interface GoBackProps {
  title: string;
}

const GoBack = ({ title }: GoBackProps) => {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <HStack align='center' justify='center' width='100%' mb='4'>
      <IconButton
        fontSize='2rem'
        aria-label='Go back'
        icon={<RiArrowLeftSLine />}
        variant='ghost'
        onClick={handleGoBack}
        pos='absolute'
        left='0'
      />
      <Heading size='md' as='h4' isTruncated>
        {title?.toLocaleUpperCase()}
      </Heading>
    </HStack>
  );
};

export default GoBack;
