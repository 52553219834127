import { Heading, Select, VStack, Image, Text } from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { Service } from 'assets/svg';
import { ratingState } from 'state/atom';

const Rating2 = () => {
  const [rating, setRating] = useRecoilState(ratingState);
  const { t } = useTranslation();

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = Number.parseInt(event.currentTarget.value);
    if (value > 0 && value < 11) setRating({ ...rating, rating2: value });
  };

  return (
    <VStack spacing='8'>
      <Heading>{t('Service')}</Heading>
      <Image
        src={Service}
        w={{ base: '66vw', lg: '33vw', xl: '23vw' }}
        alt='Two people sitting in a restaurant'
      />
      <Text fontWeight='semibold'>{t('ServiceText')}</Text>
      <Select
        defaultValue={rating.rating2}
        size='lg'
        variant='filled'
        focusBorderColor='brandorange.300'
        onChange={handleChange}
        textAlign='center'
        fontWeight='extrabold'
      >
        <option value={1}>1</option>
        <option value={2}>2</option>
        <option value={3}>3</option>
        <option value={4}>4</option>
        <option value={5}>5</option>
        <option value={6}>6</option>
        <option value={7}>7</option>
        <option value={8}>8</option>
        <option value={9}>9</option>
        <option value={10}>10</option>
      </Select>
    </VStack>
  );
};

export default Rating2;
