import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'lib/react-query';
import { getBackendAPI } from 'proxy';

function getGroups() {
  return getBackendAPI().groupControllerFetchGroups();
}

export function useGroups() {
  return useQuery(queryKeys.groups.lists(), async () => getGroups());
}
