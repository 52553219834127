import { useAuth0 } from '@auth0/auth0-react';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  HStack,
  Image,
  Link,
  List,
  ListItem,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

import firstMedal from 'assets/images/first-medal.png';
import secondMedal from 'assets/images/second-medal.png';
import thirdMedal from 'assets/images/third-medal.png';
import podium from 'assets/images/winners-podium.png';
import { ResultDto } from 'proxy';

type TResultProps = {
  result: ResultDto;
};

const ResultItem = ({ result: { groupId, name, previousRound } }: TResultProps) => {
  const { user } = useAuth0();

  const showUserPlacementImg = (prevRound: ResultDto['previousRound']): string => {
    const userPlacement = prevRound.findIndex((value) => value?.owner === user?.sub);
    switch (userPlacement) {
      case 0:
        return firstMedal;
      case 1:
        return secondMedal;
      case 2:
        return thirdMedal;
      default:
        return podium;
    }
  };

  const showTablePlacementImg = (placement: number): string => {
    switch (placement) {
      case 0:
        return firstMedal;
      case 1:
        return secondMedal;
      case 2:
        return thirdMedal;
      default:
        return podium;
    }
  };

  const ratingColor = (rating: number) => {
    if (rating >= 8) {
      return 'green';
    } else if (rating >= 5) {
      return 'orange';
    } else {
      return 'red';
    }
  };

  //TODO why owner here?
  // const highlightUserPlacement = (placementUser: ResultDto['owner']): string => {
  //   if (placementUser === user?.sub) {
  //     return '2px solid #00e6d4';
  //   } else {
  //     return '';
  //   }
  // };

  const highlightPlacement = (placement: number): string => {
    switch (placement) {
      case 0:
        return 'linear-gradient(90deg, rgba(255,255,255,1) 15%, rgba(255,215,0,1) 100%)';
      case 1:
        return 'linear-gradient(90deg, rgba(255,255,255,1) 25%, rgba(192,192,192,1) 100%)';
      case 2:
        return 'linear-gradient(90deg, rgba(255,255,255,1) 25%, rgba(205,127,50,1) 100%)';
      default:
        return '';
    }
  };

  return (
    <AccordionItem
      pos='relative'
      w={{ base: '90vw', md: '50vw', lg: '43vw', xl: '35vw' }}
      rounded='2xl'
      boxShadow='0 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 6px 1px rgba(0, 0, 0, 0.1)'
      my='4'
      p='2'
      _hover={{ cursor: 'pointer' }}
    >
      <AccordionButton>
        <HStack flex='1' textAlign='left'>
          <Image
            src={showUserPlacementImg(previousRound)}
            alt='Placement'
            w={{ base: '20vw', md: '5vw', lg: '7.5vw', xl: '5vw' }}
            p='2'
          />
          <Link as={RouterLink} to={`groups/${groupId}`} fontWeight='bold' fontSize='lg'>
            {name?.toLocaleUpperCase()}
          </Link>
        </HStack>
        <AccordionIcon boxSize={8} />
      </AccordionButton>
      <AccordionPanel pb={4}>
        <List spacing={2} textAlign='left'>
          {previousRound.map((placement, key) => (
            <ListItem
              key={placement?.id}
              background={highlightPlacement(key)}
              // border={highlightUserPlacement(placement?.owner)}
              rounded='2xl'
              p={1}
            >
              <HStack align='center' justify='space-between'>
                <HStack align='center' justify='space-between'>
                  <Image
                    src={showTablePlacementImg(key)}
                    alt='Placement'
                    w={{ base: '15vw', md: '5vw', lg: '7.5vw', xl: '5vw' }}
                    p='2'
                  />
                  <Link
                    fontWeight='bold'
                    fontSize='lg'
                    href={placement?.restaurant?.url}
                    isExternal
                  >
                    {placement?.restaurant?.name}
                  </Link>
                </HStack>
                <Badge variant='solid' colorScheme={ratingColor(placement?.avgRating)}>
                  {new Intl.NumberFormat('de-DE', {
                    maximumSignificantDigits: 3,
                  }).format(placement?.avgRating)}
                </Badge>
              </HStack>
            </ListItem>
          ))}
        </List>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default ResultItem;
