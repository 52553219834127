import {
  useDisclosure,
  Drawer,
  IconButton,
  Icon,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Button,
  VStack,
  Text,
  HStack,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { RiAddLine, RiTeamLine, RiGroup2Line } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';

const CreateJoinDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const { t } = useTranslation();

  const handleCreateClick = () => {
    onClose();
    history.push('/groups/create');
  };

  const handleJoinClick = () => {
    onClose();
    history.push('/groups/join');
  };

  return (
    <HStack justify='center'>
      <IconButton
        icon={<Icon as={RiAddLine} boxSize={8} />}
        variant='ghost'
        aria-label='Create or join group'
        onClick={onOpen}
        color='white'
        border='1px solid brandorange.300'
        backgroundColor='brandorange.300'
        _active={{ bgColor: 'brandorange.400' }}
        _hover={{ bgColor: 'brandorange.400' }}
        borderRadius='50%'
        height='2.5rem'
        width='2.5rem'
      />
      <Drawer placement='bottom' isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent borderTopLeftRadius='0.75rem' borderTopRightRadius='0.75rem'>
            <DrawerCloseButton />
            <DrawerHeader>{t('Looking for a group?')}</DrawerHeader>
            <DrawerBody p='0'>
              <VStack spacing='4' pt='2' pb='6'>
                <Button
                  aria-label='Join group'
                  variant='ghost'
                  onClick={handleJoinClick}
                  isFullWidth
                  justifyContent='start'
                >
                  <HStack>
                    <IconButton
                      as='div'
                      aria-label='Join group icon'
                      icon={<Icon as={RiGroup2Line} boxSize={6} />}
                      borderRadius='50%'
                    />
                    <Text>{t('Join group')}</Text>
                  </HStack>
                </Button>
                <Button
                  aria-label='Create new group'
                  variant='ghost'
                  onClick={handleCreateClick}
                  isFullWidth
                  justifyContent='start'
                >
                  <HStack>
                    <IconButton
                      as='div'
                      aria-label='Create new group icon'
                      icon={<Icon as={RiTeamLine} boxSize={6} />}
                      borderRadius='50%'
                    />
                    <Text>{t('Create group')}</Text>
                  </HStack>
                </Button>
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </HStack>
  );
};

export default CreateJoinDrawer;
