import {
  Button,
  Center,
  HStack,
  Input,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { useUpdateMeetingTime } from 'api/round';
import { queryClient, queryKeys } from 'lib/react-query';
import { GameRoundDto } from 'proxy';
import { groupState } from 'state/atom';

type TChangeDate = {
  meetingTime?: string;
  meetingDate?: Date;
};

type TChangeDateProps = {
  meetingTime?: GameRoundDto['meetingTime'];
};

const ChangeDate = ({ meetingTime }: TChangeDateProps) => {
  const [meetingDatetime, setMeetingDateTime] = useState<TChangeDate>();
  const { groupId, gameId, roundId } = useRecoilValue(groupState);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const toast = useToast();

  useEffect(() => {
    if (!meetingTime) {
      const now = new Date();
      setMeetingDateTime({
        meetingTime: format(now, 'HH:mm'),
        meetingDate: now,
      });
    } else {
      setMeetingDateTime({
        meetingTime: format(new Date(meetingTime), 'HH:mm'),
        meetingDate: new Date(meetingTime),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateMeetingTimeMutation = useUpdateMeetingTime();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'date') {
      setMeetingDateTime({
        ...meetingDatetime,
        meetingDate: new Date(event.target.value),
      });
    } else {
      setMeetingDateTime({
        ...meetingDatetime,
        meetingTime: event.target.value,
      });
    }
  };

  const handleSubmit = () => {
    // yyyy-MM-ddTHH:mm:ssZ
    const dateSubmit =
      meetingDatetime?.meetingDate && format(meetingDatetime?.meetingDate, 'yyyy-MM-dd');
    updateMeetingTimeMutation.mutate(
      {
        groupId,
        gameId,
        roundId,
        meetingTime: `${dateSubmit}T${meetingDatetime?.meetingTime}:00Z`,
      },
      {
        onSuccess: () => {
          onClose();
          queryClient.invalidateQueries(queryKeys.groups.detail(groupId));
          toast({
            position: 'top',
            title: 'Datum aktualisiert',
            description: 'Neues Datum gespeichert.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        },
        onError: () => {
          toast({
            position: 'top',
            title: 'Request failed',
            description: 'Server is not responding. Please try again later.',
            status: 'error',
            duration: 10000,
            isClosable: true,
          });
        },
      }
    );
  };

  return (
    <>
      <MenuItem onClick={onOpen}>{t('Change date')}</MenuItem>
      <Modal isOpen={isOpen} onClose={onClose} size='full'>
        <ModalOverlay h='100%' bgColor='white' />
        <ModalContent h='100%' m='0' bgColor='white'>
          <ModalHeader>{t('Change date')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody bgColor='white'>
            <Center>
              <VStack w={{ base: '90vw', lg: '33vw', xl: '25vw' }}>
                <Input
                  type='date'
                  onChange={handleChange}
                  name='date'
                  value={
                    meetingDatetime?.meetingDate &&
                    format(meetingDatetime?.meetingDate, 'yyyy-MM-dd')
                  }
                />
                <Input
                  type='time'
                  name='time'
                  onChange={handleChange}
                  value={meetingDatetime?.meetingTime}
                />
              </VStack>
            </Center>
            <HStack justify='end' mt='8'>
              <Button variant='ghost' mr={3} onClick={onClose}>
                {t('Cancel')}
              </Button>
              <Button onClick={handleSubmit} colorScheme='green'>
                {t('Save')}
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ChangeDate;
