import { IconButton } from '@chakra-ui/button';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Center,
  VStack,
  Heading,
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
  Spinner,
  useDisclosure,
  ListItem,
  List,
  Box,
  HStack,
  Image,
  Text,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiCommunityLine, RiSearchLine } from 'react-icons/ri';
import { useRecoilValue } from 'recoil';

import { City } from 'assets/svg';
import { userState } from 'state/atom';

type TCityModalProps = {
  setCity: any;
};

const SelectCityModal = ({ setCity }: TCityModalProps) => {
  const { userLanguage } = useRecoilValue(userState);
  const [search, setSearch] = useState('');
  const { onClose, isOpen, onOpen } = useDisclosure();
  const { t } = useTranslation();

  const { isFetching, data, refetch } = useQuery(
    ['cityData'],
    async () => {
      if (userLanguage?.includes('de')) {
        return fetch(
          `https://discover.search.hereapi.com/v1/discover?at=52.51604,-13.37691&q=${search}&limit=5&lang=de&apiKey=${process.env.REACT_APP_HERE_API_KEY}`
        ).then((res) => res.json());
      } else {
        return fetch(
          `https://discover.search.hereapi.com/v1/discover?at=52.51604,-13.37691&q=${search}&limit=5&lang=en&apiKey=${process.env.REACT_APP_HERE_API_KEY}`
        ).then((res) => res.json());
      }
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 86400000,
      staleTime: 86400000,
    }
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event?.target?.value);
    if (search) {
      setTimeout(() => refetch(), 1000);
    }
  };

  const handleSelection = (event: React.MouseEvent<HTMLLIElement>) => {
    setCity('city', event?.currentTarget?.textContent);
    onClose();
  };

  return (
    <>
      <IconButton
        aria-label={t('Select your city')}
        icon={<RiCommunityLine color='white' />}
        fontSize='1.5rem'
        backgroundColor='brandorange.300'
        _active={{ bgColor: 'brandorange.400' }}
        _hover={{ bgColor: 'brandorange.400' }}
        onClick={onOpen}
      />
      <Modal onClose={onClose} isOpen={isOpen} size='full'>
        <ModalOverlay h='100%' bgColor='white' />
        <ModalContent h='100%' m='0' bgColor='white'>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody bgColor='white'>
            <Center>
              <VStack w={{ base: '90vw', lg: '33vw', xl: '25vw' }}>
                <Heading as='h1' size='lg'>
                  {t('Select your city')}
                </Heading>
                <InputGroup mb='8'>
                  <InputLeftElement pointerEvents='none'>
                    <Icon as={RiSearchLine} color={search ? 'brandorange.300' : ''} boxSize={6} />
                  </InputLeftElement>
                  <Input
                    placeholder={t('Search for your city')}
                    variant='flushed'
                    focusBorderColor='brandorange.300'
                    value={search}
                    onChange={handleSearch}
                    w={{ base: '90vw', md: '50vw', lg: '33vw', xl: '25vw' }}
                  />
                </InputGroup>
                {isFetching && <Spinner />}
                <List spacing={4}>
                  {data?.items
                    ?.filter((city: any) => city.resultType === 'locality')
                    .map((filteredCity: any) => (
                      <ListItem
                        key={filteredCity?.title}
                        onClick={handleSelection}
                        _hover={{ cursor: 'pointer' }}
                      >
                        <Box pos='relative'>
                          <HStack
                            as='button'
                            pos='relative'
                            w={{
                              base: '90vw',
                              md: '50vw',
                              lg: '33vw',
                              xl: '25vw',
                            }}
                            rounded='2xl'
                            boxShadow='0 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 6px 1px rgba(0, 0, 0, 0.1)'
                            my='4'
                            p='2'
                            _hover={{ cursor: 'pointer' }}
                          >
                            <Image src={City} alt='City' w='30%' p='2' />
                            <Box textAlign='left' w='70%'>
                              <Text fontSize='lg' fontWeight='600' wordBreak='break-word'>
                                {filteredCity?.title}
                              </Text>
                            </Box>
                          </HStack>
                        </Box>
                      </ListItem>
                    ))}
                </List>
              </VStack>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SelectCityModal;
