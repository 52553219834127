import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'lib/react-query';
import { getBackendAPI } from 'proxy';

function getResults() {
  return getBackendAPI().resultControllerFetchResult();
}

export function useResults() {
  return useQuery(queryKeys.results.lists(), async () => getResults());
}
