import { useMutation } from '@tanstack/react-query';

import { getBackendAPI } from 'proxy';

function joinGroup(invitationCode: string) {
  return getBackendAPI().groupControllerJoinGroup(+invitationCode);
}

export function useJoinGroup() {
  return useMutation(joinGroup);
}
