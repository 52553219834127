import { Box, Heading, List, ListIcon, ListItem } from '@chakra-ui/react';
import { RiGroupLine, RiCommunityLine } from 'react-icons/ri';

import { GroupDto } from 'proxy';

type TGroupProps = {
  group: GroupDto;
};

const GroupCard = ({ group: { name, members, maxPlayerCount, city } }: TGroupProps) => {
  return (
    <Box
      rounded='2xl'
      boxShadow='0 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 6px 1px rgba(0, 0, 0, 0.1)'
      w={{ base: '90vw', lg: '33vw', xl: '25vw' }}
      p='5'
      color='brandblack.700'
    >
      <Heading as='h4' size='lg' isTruncated mb='4'>
        {name}
      </Heading>
      <List spacing={3} fontWeight='600'>
        <ListItem>
          <ListIcon
            color='brandblue.700'
            pos='relative'
            top='0.125em'
            as={RiGroupLine}
            boxSize={6}
          />
          {members?.length} / {maxPlayerCount}
        </ListItem>
        <ListItem>
          <ListIcon
            color='brandblue.700'
            pos='relative'
            top='0.125em'
            as={RiCommunityLine}
            boxSize={6}
          />
          {city.split(',')[0]}
        </ListItem>
      </List>
    </Box>
  );
};

export default GroupCard;
