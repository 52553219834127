import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'lib/react-query';
import { getBackendAPI } from 'proxy';

function getGroupDetail(groupId: string) {
  return getBackendAPI().groupControllerGetGroupDetail(groupId);
}

export function useGroupDetail(groupId: string) {
  return useQuery(queryKeys.groups.detail(groupId), async () => getGroupDetail(groupId));
}
