import { useAuth0 } from '@auth0/auth0-react';
import { Flex, Box, Heading, Spacer, Avatar, Badge } from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';

const Header = () => {
  const { user } = useAuth0();
  const history = useHistory();
  return (
    <Flex
      align='center'
      h='3rem'
      p='2'
      mb='4'
      borderBottom='2px solid'
      borderBottomColor='brandgreen.500'
    >
      <Box p='2'>
        <Heading size='md'>
          Gourmet Games{' '}
          <Badge variant='subtle' colorScheme='orange'>
            Early Access
          </Badge>
        </Heading>
      </Box>
      <Spacer />
      <Avatar
        as='button'
        bg='brandgreen.500'
        size='sm'
        name={user?.name}
        src={user?.picture}
        aria-label='Profile'
        onClick={() => history.push('/profile')}
      />
    </Flex>
  );
};

export default Header;
