import { useMutation } from '@tanstack/react-query';

import { getBackendAPI } from 'proxy';

function deleteMe() {
  return getBackendAPI().userControllerDeleteMe();
}

export function useDeleteMe() {
  return useMutation(deleteMe);
}
