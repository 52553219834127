import { IconButton, Icon, Button, Text, Grid } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiHomeLine, RiCalendar2Line, RiTrophyLine, RiRestaurantLine } from 'react-icons/ri';
import { useHistory, useLocation } from 'react-router-dom';

import CreateJoinDrawer from '../components/CreateJoinDrawer';

const Footer = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  return (
    <Grid
      templateColumns='repeat(5, 20vw)'
      position='fixed'
      bottom={0}
      left={0}
      py='2'
      borderTop='2px solid'
      borderTopColor='gray.200'
      zIndex='99'
      bgColor='white'
    >
      <Button
        onClick={() => history.push('/')}
        aria-label='Navigate to home'
        flexDir='column'
        variant='ghost'
        color={pathname === '/' ? 'brandgreen.500' : 'brandblack.700'}
      >
        <IconButton
          as='div'
          aria-label='Example'
          variant='ghost'
          icon={<Icon as={RiHomeLine} boxSize={6} />}
        />
        <Text fontSize='xs'>{t('Home')}</Text>
      </Button>
      <Button
        onClick={() => history.push('/groups')}
        aria-label='Navigate to groups'
        flexDir='column'
        variant='ghost'
        color={pathname.includes('groups') ? 'brandgreen.500' : 'brandblack.700'}
      >
        <IconButton
          as='div'
          aria-label='Example'
          variant='ghost'
          icon={<Icon as={RiRestaurantLine} boxSize={6} />}
        />
        <Text fontSize='xs'>{t('Groups')}</Text>
      </Button>
      <CreateJoinDrawer />
      <Button
        onClick={() => history.push('/events')}
        aria-label='Navigate to events'
        flexDir='column'
        variant='ghost'
        color={pathname.includes('events') ? 'brandgreen.500' : 'brandblack.700'}
        disabled
      >
        <IconButton
          as='div'
          aria-label='Example'
          variant='ghost'
          icon={<Icon as={RiCalendar2Line} boxSize={6} />}
        />
        <Text fontSize='xs'>{t('Events')}</Text>
      </Button>
      <Button
        onClick={() => history.push('/results')}
        aria-label='Navigate to results'
        flexDir='column'
        variant='ghost'
        color={pathname.includes('results') ? 'brandgreen.500' : 'brandblack.700'}
      >
        <IconButton
          as='div'
          aria-label='Example'
          variant='ghost'
          icon={<Icon as={RiTrophyLine} boxSize={6} />}
        />
        <Text fontSize='xs'>{t('Results')}</Text>
      </Button>
    </Grid>
  );
};

export default Footer;
