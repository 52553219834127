import { useMutation } from '@tanstack/react-query';

import { getBackendAPI, GroupRequest } from 'proxy';

function createGroup(newGroup: GroupRequest) {
  return getBackendAPI().groupControllerCreateGroup(newGroup);
}

export function useCreateGroup() {
  return useMutation(createGroup);
}
