import { useMutation } from '@tanstack/react-query';

import { getBackendAPI } from 'proxy';

function rateRound(request: { groupId: string; gameId: string; roundId: string; rating: number }) {
  return getBackendAPI().roundControllerRatingRound(
    request.groupId,
    request.gameId,
    request.roundId,
    { rating: request.rating }
  );
}

export function useRateGroup() {
  return useMutation(rateRound);
}
