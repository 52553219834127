import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';

import { environment as env } from 'environments';

const Auth0ProviderWithHistory: React.FC = ({ children }) => {
  return (
    <Auth0Provider
      domain={env.auth0Domain}
      clientId={env.auth0ClientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: env.auth0Audience,
      }}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      cacheLocation='localstorage'
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
