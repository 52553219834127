import {
  DefaultOptions,
  QueryClient,
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { PromiseValue } from 'type-fest';

import { RestaurantControllerSearchByKeywordParams } from 'proxy';

const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: true,
    refetchOnWindowFocus: 'always',
    staleTime: 5000,
    retry: false,
  },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });

export type QueryConfig<FetcherFnType extends (...args: any) => any> = UseQueryOptions<
  PromiseValue<ReturnType<FetcherFnType>>
>;

export type MutationConfig<FetcherFnType extends (...args: any) => any> = UseMutationOptions<
  PromiseValue<ReturnType<FetcherFnType>>,
  AxiosError,
  Parameters<FetcherFnType>[0]
>;

// Query Key factories => https://tkdodo.eu/blog/effective-react-query-keys
export const queryKeys = {
  groups: {
    all: ['groups'] as const,
    lists: () => [...queryKeys.groups.all, 'list'] as const,
    detail: (id: string) => [...queryKeys.groups.lists(), id] as const,
  },
  restaurants: {
    all: ['restaurants'] as const,
    search: (request: RestaurantControllerSearchByKeywordParams) =>
      [...queryKeys.groups.all, 'search', request] as const,
  },
  results: {
    all: ['results'] as const,
    lists: () => [...queryKeys.groups.all, 'results'] as const,
  },
};
