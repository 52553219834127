import { useAuth0 } from '@auth0/auth0-react';
import { Flex } from '@chakra-ui/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import LoadingSpinner from 'components/LoadingSpinner';
import { environment as env } from 'environments';
import { customAuth } from 'lib/customAuth';
import { queryClient } from 'lib/react-query';
import { userState } from 'state/atom';

import Content from './layout/Content';
import Footer from './layout/Footer';
import Header from './layout/Header';

function App() {
  const { getAccessTokenSilently, isLoading } = useAuth0();
  const [userSettings, setUserSettings] = useRecoilState(userState);

  customAuth.setAccessTokenSilently(
    getAccessTokenSilently({ authorizationParams: { audience: env.auth0Audience } })
  );

  const windowResizeHandler = (ev: any) => {
    document.documentElement.style.setProperty('--vh', ev.target.innerHeight / 100 + 'px');
  };

  const getUserLanguage = () => {
    const languageStorage = localStorage.getItem('i18nextLng');
    if (languageStorage) {
      let yelpLanguage = userSettings?.yelpLanguage;
      if (languageStorage.includes('de')) {
        yelpLanguage = 'de_DE';
      } else {
        yelpLanguage = 'en_US';
      }
      setUserSettings({
        userLanguage: languageStorage,
        yelpLanguage: yelpLanguage,
      });
    }
  };

  useEffect(() => {
    window.addEventListener('resize', windowResizeHandler);
    document.documentElement.style.setProperty('--vh', window.innerHeight / 100 + 'px');
    getUserLanguage();
    return function cleanup() {
      window.removeEventListener('resize', windowResizeHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const realHeight = getComputedStyle(document.body).getPropertyValue('--vh').slice(0, -2);

  if (isLoading) return <LoadingSpinner />;

  return (
    <QueryClientProvider client={queryClient}>
      {process.env.NODE_ENV === 'development' && (
        <ReactQueryDevtools position='bottom-left' initialIsOpen={false} />
      )}
      <Flex direction='column' height={`${100 * parseFloat(realHeight)}px`}>
        <Header />
        <Content />
        <Footer />
      </Flex>
    </QueryClientProvider>
  );
}

export default App;
