import { Center, Spinner } from '@chakra-ui/react';

const LoadingSpinner = () => {
  const realHeight = getComputedStyle(document.body).getPropertyValue('--vh').slice(0, -2);

  return (
    <Center h={`${100 * parseFloat(realHeight)}px`}>
      <Spinner />
    </Center>
  );
};

export default LoadingSpinner;
