import { Heading, Select, VStack, Image, Text } from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { Chef } from 'assets/svg';
import { ratingState } from 'state/atom';

const Rating4 = () => {
  const [rating, setRating] = useRecoilState(ratingState);
  const { t } = useTranslation();

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = Number.parseInt(event.currentTarget.value);
    if (value > 0 && value < 11) setRating({ ...rating, rating4: value });
  };

  return (
    <VStack spacing='8'>
      <Heading>{t('QualityTaste')}</Heading>
      <Image src={Chef} w={{ base: '66vw', lg: '33vw', xl: '23vw' }} alt='Chef/ Cook.' />
      <Text fontWeight='semibold'>{t('PricePerformanceText')}</Text>
      <Select
        defaultValue={rating.rating4}
        size='lg'
        variant='filled'
        focusBorderColor='brandorange.300'
        onChange={handleChange}
        textAlign='center'
        fontWeight='extrabold'
      >
        <option value={1}>1</option>
        <option value={2}>2</option>
        <option value={3}>3</option>
        <option value={4}>4</option>
        <option value={5}>5</option>
        <option value={6}>6</option>
        <option value={7}>7</option>
        <option value={8}>8</option>
        <option value={9}>9</option>
        <option value={10}>10</option>
      </Select>
    </VStack>
  );
};

export default Rating4;
