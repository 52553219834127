import { useMutation } from '@tanstack/react-query';

import { getBackendAPI } from 'proxy';

function createGame(groupId: string) {
  return getBackendAPI().gameControllerCreateNewGame(groupId);
}

export function useCreateGame() {
  return useMutation(createGame);
}
