import { useAuth0 } from '@auth0/auth0-react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Button,
  Heading,
  HStack,
  VStack,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiArrowLeftSLine } from 'react-icons/ri';

import { useDeleteMe } from 'api/user';

type TAccountProps = {
  setNav: React.Dispatch<React.SetStateAction<string>>;
};

const Account = ({ setNav }: TAccountProps) => {
  const { t } = useTranslation();
  const { user } = useAuth0();
  const { mutate, isLoading } = useDeleteMe();

  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = useRef(null);

  const handleDelete = () => {
    onClose();
    mutate();
    window.location.reload();
  };

  return (
    <VStack align='start' w={{ base: '90vw', lg: '33vw', xl: '25vw' }}>
      <HStack align='center' justify='center' width='100%' mb='4'>
        <Button
          aria-label={t('Back')}
          leftIcon={<RiArrowLeftSLine fontSize='2rem' />}
          variant='ghost'
          onClick={() => setNav('overview')}
          pos='absolute'
          left='0'
        >
          {t('Overview')}
        </Button>
      </HStack>
      <HStack spacing='4' mb='4'>
        <Avatar size='lg' bg='brandgreen.500' name={user?.name} src={user?.picture} />
        <Heading size='lg' wordBreak='break-word'>
          {user?.name}
        </Heading>
      </HStack>
      <Button colorScheme='red' isLoading={isLoading} onClick={() => setIsOpen(true)}>
        {t('Delete Account')}
      </Button>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {t('Delete Account')}
            </AlertDialogHeader>
            <AlertDialogBody>{t('Delete Account Text')}</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                {t('Cancel')}
              </Button>
              <Button colorScheme='red' onClick={handleDelete} ml={3}>
                {t('Delete')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </VStack>
  );
};

export default Account;
