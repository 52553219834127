import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ProtectedRoute from '../components/Auth/ProtectedRoute';
import NoMatch from '../components/NoMatch';
import Events from '../pages/Events';
import RateGame from '../pages/Games/RateGame';
import CreateGroup from '../pages/Groups/CreateGroup';
import GroupDetails from '../pages/Groups/GroupDetails';
import Groups from '../pages/Groups/Groups';
import JoinGroup from '../pages/Groups/JoinGroup';
import ShareGroupInvite from '../pages/Groups/ShareGroupInvite';
import Home from '../pages/Home';
import ProfileWrapper from '../pages/Profile/ProfileWrapper';
import Results from '../pages/Results/Results';

const Content = () => {
  return (
    <Switch>
      <ProtectedRoute exact path='/' component={Home} />
      <ProtectedRoute path='/events' component={Events} />
      <ProtectedRoute path='/results' component={Results} />
      <ProtectedRoute path='/groups/create' component={CreateGroup} />
      <ProtectedRoute path='/groups/join/:code?' component={JoinGroup} />
      <ProtectedRoute path='/groups/:groupId/games/:gameId/rate' component={RateGame} />
      <ProtectedRoute path='/groups/:groupId/invite/:code' component={ShareGroupInvite} />
      <ProtectedRoute path='/groups/:groupId' component={GroupDetails} />
      <ProtectedRoute path='/groups' component={Groups} />
      <ProtectedRoute path='/profile' component={ProfileWrapper} />
      <Route>
        <NoMatch />
      </Route>
    </Switch>
  );
};

export default Content;
