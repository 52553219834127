/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Backend API
 * The Backend API description
 * OpenAPI spec version: 1.0
 */
import { customAxios } from '../lib/axios';

import type {
  YelpSearchBusinessResponse,
  RestaurantControllerSearchByKeywordParams,
  UserDto,
  Auth0User,
  GroupDto,
  RestaurantRequest,
  GameDto,
  GroupRequest,
  UpdateMeetingRequest,
  RatingRequest,
  ResultDto,
} from './dtos';

export const getBackendAPI = () => {
  const restaurantControllerSearchByKeyword = (
    params: RestaurantControllerSearchByKeywordParams
  ) => {
    return customAxios<YelpSearchBusinessResponse>({
      url: `/api/restaurants/keyword`,
      method: 'get',
      params,
    });
  };

  const userControllerGetCurrentUser = () => {
    return customAxios<UserDto>({
      url: `/api/user/currentUser`,
      method: 'get',
    });
  };

  const userControllerDeleteMe = () => {
    return customAxios<void>({ url: `/api/user`, method: 'delete' });
  };

  const auth0ControllerUpdateUser = (auth0User: Auth0User) => {
    return customAxios<void>({
      url: `/auth0/users`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: auth0User,
    });
  };

  const gameControllerCreateNewGame = (id: string) => {
    return customAxios<GroupDto>({
      url: `/api/groups/${id}/games`,
      method: 'post',
    });
  };

  const gameControllerChooseRestaurant = (
    groupId: string,
    gameId: string,
    restaurantRequest: RestaurantRequest
  ) => {
    return customAxios<GroupDto>({
      url: `/api/groups/${groupId}/games/${gameId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: restaurantRequest,
    });
  };

  const gameControllerStartGame = (groupId: string, gameId: string) => {
    return customAxios<GameDto>({
      url: `/api/groups/${groupId}/games/${gameId}`,
      method: 'post',
    });
  };

  const groupControllerFetchGroups = () => {
    return customAxios<GroupDto[]>({ url: `/api/groups`, method: 'get' });
  };

  const groupControllerCreateGroup = (groupRequest: GroupRequest) => {
    return customAxios<GroupDto>({
      url: `/api/groups`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: groupRequest,
    });
  };

  const groupControllerGetGroupDetail = (id: string) => {
    return customAxios<GroupDto>({ url: `/api/groups/${id}`, method: 'get' });
  };

  const groupControllerEditGroup = (id: string, groupRequest: GroupRequest) => {
    return customAxios<GroupDto>({
      url: `/api/groups/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: groupRequest,
    });
  };

  const groupControllerJoinGroup = (inviteCode: number) => {
    return customAxios<GroupDto>({
      url: `/api/groups/join/${inviteCode}`,
      method: 'post',
    });
  };

  const groupControllerVerifyGroup = (inviteCode: number) => {
    return customAxios<GroupDto>({
      url: `/api/groups/verify/${inviteCode}`,
      method: 'get',
    });
  };

  const roundControllerUpdateMeetingTime = (
    groupId: string,
    gameId: string,
    roundId: string,
    updateMeetingRequest: UpdateMeetingRequest
  ) => {
    return customAxios<GameDto>({
      url: `/api/groups/${groupId}/games/${gameId}/${roundId}/meetingTime`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateMeetingRequest,
    });
  };

  const roundControllerStartRating = (groupId: string, gameId: string, roundId: string) => {
    return customAxios<GameDto>({
      url: `/api/groups/${groupId}/games/${gameId}/${roundId}/rating`,
      method: 'post',
    });
  };

  const roundControllerRatingRound = (
    groupId: string,
    gameId: string,
    roundId: string,
    ratingRequest: RatingRequest
  ) => {
    return customAxios<GameDto>({
      url: `/api/groups/${groupId}/games/${gameId}/${roundId}/rating`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: ratingRequest,
    });
  };

  const resultControllerFetchResult = () => {
    return customAxios<ResultDto[]>({ url: `/api/results`, method: 'get' });
  };

  return {
    restaurantControllerSearchByKeyword,
    userControllerGetCurrentUser,
    userControllerDeleteMe,
    auth0ControllerUpdateUser,
    gameControllerCreateNewGame,
    gameControllerChooseRestaurant,
    gameControllerStartGame,
    groupControllerFetchGroups,
    groupControllerCreateGroup,
    groupControllerGetGroupDetail,
    groupControllerEditGroup,
    groupControllerJoinGroup,
    groupControllerVerifyGroup,
    roundControllerUpdateMeetingTime,
    roundControllerStartRating,
    roundControllerRatingRound,
    resultControllerFetchResult,
  };
};

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export type RestaurantControllerSearchByKeywordResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getBackendAPI>['restaurantControllerSearchByKeyword']>>
>;
export type UserControllerGetCurrentUserResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getBackendAPI>['userControllerGetCurrentUser']>>
>;
export type UserControllerDeleteMeResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getBackendAPI>['userControllerDeleteMe']>>
>;
export type Auth0ControllerUpdateUserResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getBackendAPI>['auth0ControllerUpdateUser']>>
>;
export type GameControllerCreateNewGameResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getBackendAPI>['gameControllerCreateNewGame']>>
>;
export type GameControllerChooseRestaurantResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getBackendAPI>['gameControllerChooseRestaurant']>>
>;
export type GameControllerStartGameResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getBackendAPI>['gameControllerStartGame']>>
>;
export type GroupControllerFetchGroupsResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getBackendAPI>['groupControllerFetchGroups']>>
>;
export type GroupControllerCreateGroupResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getBackendAPI>['groupControllerCreateGroup']>>
>;
export type GroupControllerGetGroupDetailResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getBackendAPI>['groupControllerGetGroupDetail']>>
>;
export type GroupControllerEditGroupResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getBackendAPI>['groupControllerEditGroup']>>
>;
export type GroupControllerJoinGroupResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getBackendAPI>['groupControllerJoinGroup']>>
>;
export type GroupControllerVerifyGroupResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getBackendAPI>['groupControllerVerifyGroup']>>
>;
export type RoundControllerUpdateMeetingTimeResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getBackendAPI>['roundControllerUpdateMeetingTime']>>
>;
export type RoundControllerStartRatingResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getBackendAPI>['roundControllerStartRating']>>
>;
export type RoundControllerRatingRoundResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getBackendAPI>['roundControllerRatingRound']>>
>;
export type ResultControllerFetchResultResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getBackendAPI>['resultControllerFetchResult']>>
>;
