import { Box, Heading, List, ListItem, ListIcon, Icon } from '@chakra-ui/react';
import { addMinutes } from 'date-fns';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RiCheckboxCircleLine, RiCalendarEventLine, RiMapPinLine } from 'react-icons/ri';
import { useRecoilValue } from 'recoil';

import { userState } from 'state/atom';

type TStoreCard = {
  name: string;
  date: string | undefined;
  location: string | undefined;
};

const StoreCard = ({ name, date, location }: TStoreCard) => {
  const [formattedDate, setFormattedDate] = useState<Date>();
  const { userLanguage } = useRecoilValue(userState);
  const dateOptions: any = { dateStyle: 'short', timeStyle: 'short' };
  const { t } = useTranslation();

  useEffect(() => {
    if (date) {
      const getOffset = new Date(date).getTimezoneOffset();

      setFormattedDate(addMinutes(new Date(date), getOffset));
    }
  }, [date]);

  const getDateLocale = () => {
    if (userLanguage.includes('de')) {
      return 'de-DE';
    } else if (userLanguage.includes('GB')) {
      return 'en-GB';
    } else {
      return 'en-US';
    }
  };
  return (
    <Box
      rounded='2xl'
      boxShadow='0 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 6px 1px rgba(0, 0, 0, 0.1)'
      w={{ base: '80vw', lg: '30vw', xl: '20vw' }}
      p='5'
      bg='brandbeige.100'
      color='brandblue.700'
      opacity='0.75'
      pos='relative'
    >
      <Heading as='h4' size='md' isTruncated mb='4'>
        {name}
      </Heading>
      <Icon
        pos='absolute'
        top='2'
        right='2'
        w='8'
        h='8'
        color='brandgreen.700'
        as={RiCheckboxCircleLine}
      />
      <List spacing={3} color='brandblue.700'>
        <ListItem>
          <ListIcon
            pos='relative'
            bottom='0.125em'
            as={RiCalendarEventLine}
            color='brandorange.300'
          />
          {date
            ? `${new Intl.DateTimeFormat(getDateLocale(), dateOptions).format(formattedDate)}`
            : t('No date')}
        </ListItem>
        {location && (
          <ListItem>
            <ListIcon pos='relative' bottom='0.125em' as={RiMapPinLine} color='brandorange.300' />
            {location}
          </ListItem>
        )}
      </List>
    </Box>
  );
};

export default StoreCard;
