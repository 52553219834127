import { Box, Badge, Image, Button, Icon, HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { RiStarFill } from 'react-icons/ri';
import { SiYelp } from 'react-icons/si';

import { Cooking } from 'assets/svg';
import { Business } from 'proxy';

import SelectYelpStore from './SelectYelpStore';

type TYelpStoreProps = {
  yelpStore: Business;
};

const YelpStore = ({
  yelpStore: { id, image_url, name, categories, url, location, review_count, rating },
}: TYelpStoreProps) => {
  const { t } = useTranslation();
  return (
    <Box
      key={id}
      maxW='sm'
      borderWidth='1px'
      borderRadius='lg'
      overflow='hidden'
      w={{ base: '90vw', lg: '33vw', xl: '25vw' }}
      boxShadow='0 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 6px 1px rgba(0, 0, 0, 0.1)'
      my='8'
    >
      <Image src={image_url} alt={name} fallbackSrc={Cooking} />

      <Box p='6'>
        <Box d='flex' alignItems='baseline'>
          {categories?.map((category) => (
            <Badge
              key={category.title}
              borderRadius='full'
              mr='1'
              px='2'
              color='white'
              bgColor='brandorange.300'
            >
              {category.title}
            </Badge>
          ))}
        </Box>

        <Box mt='1' fontWeight='semibold' as='h4' lineHeight='tight' isTruncated>
          {name}
        </Box>

        {location?.display_address && (
          <Box>
            {location?.display_address[0]}, {location?.display_address[1]}
          </Box>
        )}

        <Box d='flex' mt='2' alignItems='center'>
          {rating &&
            Array(5)
              .fill('')
              .map((_, i) => (
                <Icon as={RiStarFill} key={i} color={i < rating ? 'brandorange.300' : 'gray.300'} />
              ))}
          <Box as='span' ml='2' color='gray.600' fontSize='sm'>
            {review_count} {t('Reviews')}
          </Box>
        </Box>
        <Box mt='4'>
          <HStack spacing='2' justify='space-between'>
            <Button
              variant='link'
              colorScheme='red'
              leftIcon={<SiYelp />}
              onClick={() => window.open(url)}
              size='sm'
            >
              {t('More on Yelp')}
            </Button>
            <SelectYelpStore yelpId={id} restaurantName={name} />
          </HStack>
        </Box>
      </Box>
    </Box>
  );
};

export default YelpStore;
