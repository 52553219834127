import GoBack from '../../components/Navigation/GoBack';

const RateGame = () => {
  return (
    <div>
      <GoBack title='Rate game' />
      Rate Game
    </div>
  );
};

export default RateGame;
