import { Button, VStack, Image, Heading, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useCreateGame } from 'api/game';
import { GameCouch } from 'assets/svg';
import { queryClient, queryKeys } from 'lib/react-query';
import { GroupDto } from 'proxy';

type TGameProps = {
  group: GroupDto;
};

const CreateGame = ({ group }: TGameProps) => {
  const { t } = useTranslation();
  const toast = useToast();

  const createGameMutation = useCreateGame();

  const handleCreateGame = () => {
    createGameMutation.mutate(group.id, {
      onSuccess: () => queryClient.invalidateQueries(queryKeys.groups.detail(group.id)),
      onError: () => {
        toast({
          position: 'top',
          title: 'Request failed',
          description: 'Server is not responding. Please try again later.',
          status: 'error',
          duration: 10000,
          isClosable: true,
        });
      },
    });
  };

  return (
    <VStack>
      <Image
        src={GameCouch}
        w={{ base: '66vw', lg: '33vw', xl: '23vw' }}
        alt='Friends watching a game'
      />
      <Heading textAlign='center' as='h4' size='md' w={{ base: '66vw', lg: '33vw', xl: '23vw' }}>
        {group?.members?.length !== group?.maxPlayerCount
          ? t('Not enough players')
          : t('No active game')}
      </Heading>
      <Button
        bgColor='brandorange.300'
        _hover={{ bgColor: 'brandorange.400' }}
        _active={{ bgColor: 'brandorange.400' }}
        color='white'
        w={{ base: '90vw', lg: '33vw', xl: '25vw' }}
        onClick={handleCreateGame}
        isLoading={createGameMutation.isLoading}
        disabled={group?.members?.length !== group?.maxPlayerCount}
      >
        {t('Create Game')}
      </Button>
      ;
    </VStack>
  );
};

export default CreateGame;
