import { useAuth0 } from '@auth0/auth0-react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Center,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { RiArrowRightLine, RiArrowLeftLine } from 'react-icons/ri';
import { useRecoilState } from 'recoil';

import { GameRoundDto } from 'proxy';
import { ratingState } from 'state/atom';

import RatingStepper from './RatingStepper';

type TRatingModal = {
  name: string;
  owner: string;
  ratings: GameRoundDto['ratings'];
};

const RatingModal = ({ name, owner, ratings }: TRatingModal) => {
  const { user } = useAuth0();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const [globalRatingState, setRatingState] = useRecoilState(ratingState);

  const handleNext = () => {
    if (globalRatingState.currentIndex < 5) {
      setRatingState({
        ...globalRatingState,
        currentIndex: globalRatingState.currentIndex + 1,
      });
    }
  };

  const handlePrev = () => {
    if (globalRatingState.currentIndex > 0) {
      setRatingState({
        ...globalRatingState,
        currentIndex: globalRatingState.currentIndex - 1,
      });
    }
  };

  const renderRateText = () => {
    if (user?.sub === owner) {
      return (
        <Alert status='info' rounded='2xl'>
          <AlertIcon />
          {t("You can't rate your own selection")}.
        </Alert>
      );
    } else if (user?.sub && ratings.includes(user?.sub)) {
      return (
        <Alert
          status='success'
          variant='subtle'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          textAlign='center'
          height='200px'
          rounded='2xl'
        >
          <AlertIcon boxSize='40px' mr={0} />
          <AlertTitle mt={4} mb={1} fontSize='lg'>
            {t('RatingSubmitted')}
          </AlertTitle>
          <AlertDescription maxWidth='sm'>{t("You've already rated")}.</AlertDescription>
        </Alert>
      );
    } else {
      return (
        <Button
          color='white'
          bgColor='brandorange.300'
          _hover={{ bgColor: 'brandorange.400' }}
          _active={{ bgColor: 'brandorange.400' }}
          w={{ base: '90vw', lg: '33vw', xl: '15vw' }}
          onClick={onOpen}
          disabled={disableButton()}
          wordBreak='break-word'
        >
          {t('Rate')}
        </Button>
      );
    }
  };

  const disableButton = () => {
    if (user?.sub) {
      if (user?.sub === owner || ratings.includes(user?.sub)) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <>
      {renderRateText()}
      <Modal onClose={onClose} isOpen={isOpen} size='full'>
        <ModalOverlay h='100%' bgColor='white' />
        <ModalContent h='100%' m='0' bgColor='white'>
          <ModalHeader pos='relative' bottom='0.5rem'>
            {name}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody bgColor='white'>
            <Center>
              <VStack w={{ base: '90vw', lg: '33vw', xl: '25vw' }}>
                <RatingStepper onClose={onClose} />
                <HStack justify='space-between' w='100%'>
                  <IconButton
                    aria-label='Previous'
                    disabled={globalRatingState.currentIndex === 0}
                    onClick={handlePrev}
                    icon={<RiArrowLeftLine fontSize='1.5rem' />}
                  />
                  <IconButton
                    aria-label='Next'
                    disabled={globalRatingState.currentIndex === 5}
                    onClick={handleNext}
                    icon={<RiArrowRightLine fontSize='1.5rem' />}
                  />
                </HStack>
              </VStack>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RatingModal;
