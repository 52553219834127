import { useMutation } from '@tanstack/react-query';

import { getBackendAPI } from 'proxy';

function verifyGroup(invitationCode: string) {
  return getBackendAPI().groupControllerVerifyGroup(+invitationCode);
}

export function useVerifyGroup() {
  return useMutation(verifyGroup);
}
