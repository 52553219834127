import { Image, VStack, Heading } from '@chakra-ui/react';

import Logo64 from 'assets/images/Logo64px.png';

const Home = () => {
  return (
    <VStack>
      <Heading>Welcome to the</Heading>
      <Image src={Logo64} />
      <Heading>Gourmet Games</Heading>
    </VStack>
  );
};

export default Home;
