import { Button, Heading, VStack, Image, Box, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { useRateGroup } from 'api/round';
import { Calc } from 'assets/svg';
import { queryClient, queryKeys } from 'lib/react-query';
import { groupState, ratingState } from 'state/atom';
import { ratingTotalState } from 'state/selector';

import Quote from './Quote';

type TRatingTotalProps = {
  onClose: () => void;
};

const RatingTotal = ({ onClose }: TRatingTotalProps) => {
  const total = useRecoilValue(ratingTotalState);
  const resetRating = useResetRecoilState(ratingState);
  const { t } = useTranslation();
  const { groupId, gameId, roundId } = useRecoilValue(groupState);
  const toast = useToast();

  const mutation = useRateGroup();

  const submitRating = () => {
    mutation.mutate(
      { groupId, gameId, roundId, rating: Number.parseFloat(total) },
      {
        onSuccess: () => {
          resetRating();
          onClose();
          queryClient.invalidateQueries(queryKeys.groups.detail(groupId));
          toast({
            position: 'top',
            title: t('RatingSubmitted'),
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        },
      }
    );
  };

  const showText = (): string => {
    const totalNumber = Number.parseFloat(total) || 5;
    if (totalNumber <= 3.99) {
      return t('LowTotalRating');
    } else if (totalNumber > 3 && totalNumber <= 6.99) {
      return t('MediumTotalRating');
    } else if (totalNumber >= 7 && totalNumber < 10) {
      return t('HighTotalRating');
    } else {
      return t('HighestTotalRating');
    }
  };

  return (
    <VStack>
      <Heading>{t('Summary')}</Heading>
      <Image src={Calc} w={{ base: '66vw', lg: '33vw', xl: '23vw' }} alt='Calculator.' />
      <Box border='2px' borderRadius='lg' p='2' fontSize='lg' fontWeight='extrabold'>
        {total} {t('OutOf10Points')}
      </Box>
      <Quote quoteText={showText()} />
      <Button
        color='white'
        bgColor='brandgreen.700'
        _hover={{ bgColor: 'brandgreen.700' }}
        _active={{ bgColor: 'brandgreen.700' }}
        onClick={submitRating}
        isLoading={mutation?.isLoading}
      >
        {t('Submit rating')}
      </Button>
    </VStack>
  );
};

export default RatingTotal;
