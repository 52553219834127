import { useRecoilValue } from 'recoil';

import { ratingState } from 'state/atom';

import Rating0 from './Rating0';
import Rating1 from './Rating1';
import Rating2 from './Rating2';
import Rating3 from './Rating3';
import Rating4 from './Rating4';
import RatingTotal from './RatingTotal';

type TRatingStepperProps = {
  onClose: () => void;
};

const RatingStepper = ({ onClose }: TRatingStepperProps) => {
  const { currentIndex } = useRecoilValue(ratingState);
  const handleIndex = () => {
    switch (currentIndex) {
      case 0:
        return <Rating0 />;
      case 1:
        return <Rating1 />;
      case 2:
        return <Rating2 />;
      case 3:
        return <Rating3 />;
      case 4:
        return <Rating4 />;
      case 5:
        return <RatingTotal onClose={onClose} />;
      default:
        break;
    }
  };

  return <div>{handleIndex()}</div>;
};

export default RatingStepper;
