import {
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  Flex,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Account from '../../components/Profile/Account';
import ProfileOverview from '../../components/Profile/ProfileOverview';

const ProfileWrapper = () => {
  const [currentNav, setNav] = useState('overview');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (!isOpen) {
      onOpen();
    }
  }, [onOpen, isOpen]);

  const renderNav = () => {
    switch (currentNav) {
      case 'overview':
        return <ProfileOverview setNav={setNav} />;
      case 'account':
        return <Account setNav={setNav} />;
      default:
        return 'overview';
    }
  };

  return (
    <Drawer onClose={onClose} isOpen={isOpen} placement='bottom'>
      <DrawerOverlay h='100%'>
        <DrawerContent h='100%'>
          <DrawerCloseButton onClick={() => history.goBack()} />
          <DrawerHeader>{t('Profile')}</DrawerHeader>
          <DrawerBody>
            <Flex flexDir='column' align='center' justify='center' mb='4'>
              {renderNav()}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default ProfileWrapper;
