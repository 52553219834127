import { extendTheme, ChakraProvider, CSSReset } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import App from './App';
import './index.css';
import Auth0ProviderWithHistory from './components/Auth/Auth0ProviderWithHistory';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './i18n';

const theme = extendTheme({
  styles: {
    global: {
      body: {
        color: 'brandblack.700',
      },
    },
  },
  colors: {
    brandgreen: {
      50: '#d7ffff',
      100: '#aafffa',
      200: '#7afff5',
      300: '#48fff1',
      400: '#1affed',
      // 500 is main
      500: '#00e6d4',
      600: '#00b3a5',
      700: '#008176',
      800: '#004e47',
      900: '#001c19',
    },
    brandbeige: {
      50: '#fff2e1',
      // 100 is main
      100: '#fddab5',
      200: '#fac186',
      300: '#f7aa56',
      400: '#f69129',
      500: '#dc7714',
      600: '#ac5d0e',
      700: '#7b4308',
      800: '#4a2702',
      900: '#1c0c00',
    },
    brandorange: {
      50: '#ffe6e0',
      100: '#ffbcb1',
      200: '#ff907f',
      // 300 is main
      300: '#ff644d',
      400: '#fe391b',
      500: '#e52101',
      600: '#b31800',
      700: '#810f00',
      800: '#4f0700',
      900: '#200000',
    },
    brandblue: {
      50: '#ecefff',
      100: '#cad0ea',
      200: '#a8b1d6',
      300: '#8692c5',
      400: '#6473b4',
      500: '#4a5a9a',
      600: '#3a4679',
      // 700 is main
      700: '#293257',
      800: '#171e36',
      900: '#050a17',
    },
    brandblack: {
      50: '#edf2fc',
      100: '#d4d7e0',
      200: '#b9bcc6',
      300: '#9ea2ae',
      400: '#828796',
      500: '#686d7c',
      600: '#515561',
      // 700 is main
      700: '#393d46',
      800: '#21242c',
      900: '#070c15',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Auth0ProviderWithHistory>
      <RecoilRoot>
        <Router>
          <ChakraProvider theme={theme}>
            <CSSReset />
            <App />
          </ChakraProvider>
        </Router>
      </RecoilRoot>
    </Auth0ProviderWithHistory>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
