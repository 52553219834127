import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next/';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { useChooseRestaurant } from 'api/game';
import { queryClient, queryKeys } from 'lib/react-query';
import { Business } from 'proxy';

import { groupState } from '../../state/atom';

type TSYStore = {
  yelpId: Business['id'];
  restaurantName: Business['name'];
};

const SelectYelpStore = ({ yelpId, restaurantName }: TSYStore) => {
  const { groupId, gameId } = useRecoilValue(groupState);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const cancelRef = React.useRef(null);
  const toast = useToast();
  const { t } = useTranslation();

  const mutation = useChooseRestaurant();

  const handleSelection = () => {
    if (yelpId && restaurantName) {
      const selectionYelp = { yelpId, restaurantName };
      mutation.mutate(
        { groupId, gameId, restaurant: selectionYelp },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.groups.detail(groupId));
            history.push(`/groups/${groupId}`);
          },
          onError: () => {
            onClose();
            toast({
              position: 'top',
              title: `${restaurantName} ${t('already selected')}.`,
              description: t(
                'Another user already made this selection, please select another location.'
              ),
              status: 'warning',
              duration: 10000,
              isClosable: true,
            });
          },
        }
      );
    }
  };

  return (
    <>
      <Button
        bgColor='brandorange.300'
        color='white'
        _hover={{ bgColor: 'brandorange.400' }}
        _active={{ bgColor: 'brandorange.400' }}
        onClick={onOpen}
      >
        {t('Select')}
      </Button>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent w={{ base: '90vw', lg: '33vw', xl: '25vw' }}>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {t('Confirm your selection')}
            </AlertDialogHeader>

            <AlertDialogBody>
              {t("Are you sure? You can't undo this action afterwards.")}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                {t('Cancel')}
              </Button>
              <Button
                color='white'
                bgColor='brandgreen.700'
                _hover={{ bgColor: 'brandgreen.700' }}
                _active={{ bgColor: 'brandgreen.700' }}
                onClick={handleSelection}
                ml={3}
              >
                {t('Confirm')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default SelectYelpStore;
