import { Text, Heading, Image, VStack, Button } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { Breakfast404 } from 'assets/svg';

const NoMatch = () => {
  const history = useHistory();
  return (
    <VStack h='80%'>
      <Image src={Breakfast404} h='50%' alt='Breakfast plate.' />
      <Heading as='h1' size='4xl' color='red.500'>
        Error 404
      </Heading>
      <Heading as='h2' size='xl'>
        Page Not Found
      </Heading>
      <Text>We could not find this page.</Text>
      <Button onClick={() => history.push('/')}>Return Home</Button>
    </VStack>
  );
};

export default NoMatch;
