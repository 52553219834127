import {
  useClipboard,
  Input,
  Button,
  VStack,
  Heading,
  List,
  ListIcon,
  ListItem,
  HStack,
  Text,
  IconButton,
  useToast,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Modal,
  ModalBody,
  ModalHeader,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { FaTwitter, FaWhatsapp } from 'react-icons/fa';
import { FiCheckCircle, FiShare } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';

import QrModal from 'components/Group/QrModal';

type TParams = {
  code: string;
};

const ShareGroupInvite = () => {
  const history = useHistory();
  const { code } = useParams<TParams>();
  const [value] = React.useState(`${window.origin}/groups/join/${code}`);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { hasCopied, onCopy } = useClipboard(value);
  const toast = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    onOpen();
    return () => {
      onClose();
    };
  });

  useEffect(() => {
    if (hasCopied)
      toast({
        description: t('Copied to your clipboard!'),
        status: 'success',
        duration: 2000,
        isClosable: false,
        position: 'bottom',
      });
  }, [hasCopied, toast, t]);

  const handleClose = () => {
    history.goBack();
  };

  const handleShare = async (event: React.MouseEvent<HTMLButtonElement>) => {
    switch (event.currentTarget.name) {
      case 'share':
        try {
          await navigator.share({
            title: 'gourmetgames.app',
            text: t('Share link'),
            url: `${value}`,
          });
        } catch (err) {
          console.error('Error: ' + err);
        }
        break;
      case 'whatsapp':
        window.open(`https://wa.me/?text=${encodeURI(`${t('Share link')} ${value}`)}`);
        break;
      case 'twitter':
        window.open(
          `https://twitter.com/intent/tweet?text=${encodeURI(`${t('Share link')} ${value})`)}`
        );
        break;
      default:
        break;
    }
  };

  return (
    <Modal onClose={handleClose} isOpen={isOpen} size='full'>
      <ModalOverlay />
      <ModalContent m='0'>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            <VStack spacing='8' w={{ base: '90vw', lg: '33vw', xl: '25vw' }}>
              <Heading as='h2' size='lg'>
                {t('Eating is better with Foodies')}
              </Heading>
              <List spacing={3}>
                <ListItem color='#2c365e'>
                  <ListIcon as={FiCheckCircle} color='green.500' />
                  {t('Get inspired by your Foodies')}
                </ListItem>
                <ListItem>
                  <ListIcon as={FiCheckCircle} color='green.500' />
                  {t('Rate each others choices')}
                </ListItem>
                <ListItem>
                  <ListIcon as={FiCheckCircle} color='green.500' />
                  {t('Eat socially, get together more often')}
                </ListItem>
                <ListItem>
                  <ListIcon as={FiCheckCircle} color='green.500' />
                  {t('Find out who is the real gourmet')}
                </ListItem>
              </List>
              <Text>{t('Invite your Foodies!')}</Text>
              <HStack spacing='8'>
                <IconButton
                  name='whatsapp'
                  size='lg'
                  aria-label='Share with WhatsApp'
                  colorScheme='whatsapp'
                  icon={<FaWhatsapp />}
                  onClick={handleShare}
                />
                <IconButton
                  name='twitter'
                  size='lg'
                  colorScheme='twitter'
                  aria-label='Share with Twitter'
                  icon={<FaTwitter />}
                  onClick={handleShare}
                />
                <QrModal inviteLink={value} />
                {(isAndroid || isIOS) && (
                  <IconButton
                    name='share'
                    size='lg'
                    aria-label='Share with other apps'
                    icon={<FiShare />}
                    onClick={handleShare}
                  />
                )}
              </HStack>
              <Text>{t('or copy your link')}:</Text>
              <HStack w={{ base: '90vw', lg: '33vw', xl: '25vw' }}>
                <Input textAlign='center' value={value} isReadOnly />
                <Button
                  color='white'
                  bgColor='brandbeige.300'
                  _hover={{ bgColor: 'brandorange.400' }}
                  _active={{ bgColor: 'brandorange.400' }}
                  onClick={onCopy}
                >
                  {hasCopied ? t('Copied') : t('Copy')}
                </Button>
              </HStack>
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ShareGroupInvite;
