import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RiSettings3Fill } from 'react-icons/ri';

import { useEditGroup } from 'api/group';
import { queryClient, queryKeys } from 'lib/react-query';
import { GroupDto, GroupRequest } from 'proxy';

import SelectCityModal from './SelectCityModal';

export type TGroupResponse = {
  data: GroupDto;
};

type TEditGroupProps = {
  group: GroupDto;
};

const EditGroupModal = ({ group }: TEditGroupProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<GroupRequest>();
  const toast = useToast();
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const mutation = useEditGroup();

  const onSubmit = (newGroup: GroupRequest) => {
    mutation.mutate(
      { groupId: group.id, group: newGroup },
      {
        onSuccess: () => {
          onClose();
          queryClient.invalidateQueries(queryKeys.groups.detail(group.id));
          toast({
            position: 'top',
            title: t('Group updated'),
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        },
        onError: () => {
          toast({
            position: 'top',
            title: 'Request failed',
            description: 'Server is not responding. Please try again later.',
            status: 'error',
            duration: 10000,
            isClosable: true,
          });
        },
      }
    );
  };

  return (
    <>
      <IconButton
        aria-label='Change group settings'
        icon={<Icon as={RiSettings3Fill} boxSize={5} />}
        color='brandblack.600'
        isRound
        pos='absolute'
        top='11.25rem'
        border='1px solid'
        borderColor='brandblack.600'
        onClick={onOpen}
      />
      <Modal onClose={onClose} isOpen={isOpen} size='full'>
        <ModalOverlay h='100%' bgColor='white' />
        <ModalContent h='100%' m='0' bgColor='white'>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody bgColor='white'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Center>
                <VStack spacing={8} w={{ base: '90vw', lg: '33vw', xl: '25vw' }}>
                  <FormControl isInvalid={!!errors?.name}>
                    <FormLabel htmlFor='name'>{t('Group name')}</FormLabel>
                    <Input
                      id='name'
                      {...register('name', {
                        value: group?.name,
                        required: true,
                        minLength: {
                          value: 2,
                          message: 'Minimum length is 2 characters.',
                        },
                        maxLength: {
                          value: 40,
                          message: 'Maximum length is 40 characters.',
                        },
                      })}
                    />
                    <FormErrorMessage>
                      {errors?.name && t('Please enter a group name')}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!errors?.city}>
                    <FormLabel htmlFor='city'>{t('City')}</FormLabel>
                    <HStack>
                      <Input
                        readOnly
                        id='city'
                        placeholder={t('Select your city')}
                        {...register('city', {
                          value: group?.city,
                          required: true,
                          maxLength: 50,
                        })}
                      />
                      <SelectCityModal setCity={setValue} />
                    </HStack>
                    <FormErrorMessage>
                      {errors.city && t('Please enter a valid city')}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!errors?.maxPlayerCount}>
                    <FormLabel htmlFor='maxPlayerCount'>{t('Number of players')}</FormLabel>
                    <NumberInput
                      defaultValue={group?.maxPlayerCount}
                      min={
                        group?.members?.length === 1
                          ? group?.members?.length + 1
                          : group?.members?.length
                      }
                      max={12}
                    >
                      <NumberInputField
                        {...register('maxPlayerCount', {
                          required: true,
                          min:
                            group?.members?.length === 1
                              ? group?.members?.length + 1
                              : group?.members?.length,
                          max: 12,
                        })}
                      />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <FormErrorMessage>
                      {errors?.maxPlayerCount && t('Please enter number of players')}
                    </FormErrorMessage>
                  </FormControl>
                  {/* <FormControl isInvalid={!!errors?.ratingDeadline}>
                    <FormLabel htmlFor='ratingDeadline'>
                      {t('Rating deadline')}
                    </FormLabel>
                    <NumberInput
                      defaultValue={props?.group?.ratingDeadline}
                      min={6}
                      max={24}
                    >
                      <NumberInputField
                        {...register('ratingDeadline', {
                          required: true,
                          min: 6,
                          max: 24,
                        })}
                      />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <FormErrorMessage>
                      {errors?.ratingDeadline &&
                        t('Please enter rating deadline')}
                    </FormErrorMessage>
                  </FormControl> */}
                  <Button
                    color='white'
                    bgColor='brandorange.300'
                    _hover={{ bgColor: 'brandorange.400' }}
                    _active={{ bgColor: 'brandorange.400' }}
                    w={{ base: '90vw', lg: '33vw', xl: '25vw' }}
                    isLoading={mutation.isLoading}
                    type='submit'
                  >
                    {t('Update group')}
                  </Button>
                </VStack>
              </Center>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditGroupModal;
