import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Switch,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RiCheckboxCircleFill, RiSearchLine } from 'react-icons/ri';
import { useRecoilValue } from 'recoil';

import { useChooseRestaurant, useSearchRestaurant } from 'api/game';
import { Cooking } from 'assets/svg';
import { queryClient, queryKeys } from 'lib/react-query';
import { RestaurantRequest } from 'proxy';
import { groupState, userState } from 'state/atom';

import YelpStore from './YelpStore';

type TAddStore = {
  userHasStore: boolean;
  groupCity: string;
};

const AddStore = ({ userHasStore, groupCity }: TAddStore) => {
  const { yelpLanguage } = useRecoilValue(userState);
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [isManualActive, setManualActive] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { groupId, gameId } = useRecoilValue(groupState);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<RestaurantRequest>({
    defaultValues: { restaurantName: '', restaurantUrl: '' },
    mode: 'onChange',
  });
  const { isLoading, data } = useSearchRestaurant({
    keyword: search,
    location: groupCity,
    locale: yelpLanguage,
  });

  const chooseRestaurantMutation = useChooseRestaurant();

  const handleClick = () => {
    if (userHasStore) {
      return;
    } else {
      onOpen();
    }
  };

  const handleCustomRestaurant = (restaurant: RestaurantRequest) => {
    chooseRestaurantMutation.mutate(
      { groupId, gameId, restaurant },
      { onSuccess: () => onClose() }
    );
  };

  const handleManualCheck = () => {
    setManualActive(!isManualActive);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    if (search) {
      setTimeout(
        () =>
          queryClient.invalidateQueries(
            queryKeys.restaurants.search({
              keyword: search,
              location: groupCity,
              locale: yelpLanguage,
            })
          ),
        1000
      );
    }
  };

  return (
    <Fragment>
      <Box pos='relative'>
        <HStack
          as='button'
          pos='relative'
          w={{ base: '90vw', md: '50vw', lg: '33vw', xl: '25vw' }}
          rounded='2xl'
          boxShadow='0 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 6px 1px rgba(0, 0, 0, 0.1)'
          my='2'
          p='2'
          opacity={userHasStore ? '0.35' : '1'}
          _hover={{ cursor: userHasStore ? 'not-allowed' : 'pointer' }}
          disabled={userHasStore}
          onClick={handleClick}
        >
          <Image src={Cooking} alt='Hamburger' w='25%' p='2' />
          <Box textAlign='left' w='80%'>
            <Text fontSize='sm' fontWeight='600'>
              {t('Add your restaurant now')}
            </Text>
            <Text fontSize='sm'>
              {t('The game starts when everyone has made their selection.')}
            </Text>
          </Box>
        </HStack>
        {userHasStore && (
          <Icon
            pos='absolute'
            top='8'
            right='2'
            boxSize='10'
            color='brandgreen.700'
            as={RiCheckboxCircleFill}
          />
        )}
      </Box>
      <Modal onClose={onClose} isOpen={isOpen} size='full'>
        <ModalOverlay h='100%' bgColor='white' />
        <ModalContent h='100%' m='0' bgColor='white'>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody bgColor='white'>
            <Center>
              <VStack w={{ base: '90vw', lg: '33vw', xl: '25vw' }}>
                <Heading as='h1' size='lg'>
                  {t('Choose your restaurant')}
                </Heading>
                <Switch colorScheme='orange' checked={isManualActive} onChange={handleManualCheck}>
                  Restaurant ist nicht auf Yelp
                </Switch>

                {!isManualActive ? (
                  <Fragment>
                    <InputGroup mb='8'>
                      <InputLeftElement pointerEvents='none'>
                        <Icon
                          as={RiSearchLine}
                          color={search ? 'brandorange.300' : ''}
                          boxSize={6}
                        />
                      </InputLeftElement>
                      <Input
                        placeholder={t('Search for your restaurant')}
                        variant='flushed'
                        focusBorderColor='brandorange.300'
                        value={search}
                        onChange={handleSearch}
                        w={{ base: '90vw', md: '50vw', lg: '33vw', xl: '25vw' }}
                      />
                    </InputGroup>
                    {isLoading && <Spinner />}
                    {data?.businesses?.map((store) => (
                      <YelpStore key={store.id} yelpStore={store} />
                    ))}
                  </Fragment>
                ) : (
                  <form onSubmit={handleSubmit(handleCustomRestaurant)}>
                    <VStack spacing={4}>
                      <FormControl isInvalid={!!errors.restaurantName}>
                        <FormLabel htmlFor='restaurantName'>Name des Restaurants</FormLabel>
                        <Input
                          id='restaurantName'
                          {...register('restaurantName', { required: true })}
                        />
                        <FormHelperText>Bitte genau den Namen eingeben.</FormHelperText>
                      </FormControl>
                      <FormControl isInvalid={!!errors.restaurantUrl}>
                        <FormLabel htmlFor='restaurantUrl'>
                          Google Maps Link des Restaurants
                        </FormLabel>
                        <Input
                          id='restaurantUrl'
                          {...register('restaurantUrl', {
                            required: true,
                            pattern: {
                              value:
                                // eslint-disable-next-line no-useless-escape
                                /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
                              message: 'Bitte valide URL eingeben',
                            },
                          })}
                        />
                        {!!errors.restaurantUrl && (
                          <FormErrorMessage>{errors.restaurantUrl.message}</FormErrorMessage>
                        )}
                      </FormControl>
                      <Button
                        color='white'
                        bgColor='brandorange.300'
                        _hover={{ bgColor: 'brandorange.400' }}
                        _active={{ bgColor: 'brandorange.400' }}
                        w={{ base: '90vw', lg: '33vw', xl: '25vw' }}
                        isLoading={chooseRestaurantMutation.isLoading}
                        type='submit'
                        disabled={!isValid}
                      >
                        Senden
                      </Button>
                    </VStack>
                  </form>
                )}
              </VStack>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default AddStore;
