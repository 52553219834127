import { Box, useColorModeValue, Flex, Text } from '@chakra-ui/react';

import QuoteIcon from './QuoteIcon';

type QuoteType = {
  quoteText: string;
};

const Quote = ({ quoteText }: QuoteType) => {
  return (
    <Box as='section' bg={useColorModeValue('gray.50', 'gray.800')}>
      <Box maxW='3xl' mx='auto' px={{ base: '6', md: '8' }} pt='12' pb='16'>
        <Flex direction='column' align='center' textAlign='center'>
          <QuoteIcon
            color={useColorModeValue('gray.300', 'gray.600')}
            fontSize={{ base: '3xl', md: '6xl' }}
          />
          <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight='medium' mt='6'>
            &ldquo;{quoteText}&rdquo;
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};

export default Quote;
