import { Box, HStack, Text, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';

import { Hamburger, Cupcake, StreetFood, Breakfast404 } from 'assets/svg';
import { GroupDto } from 'proxy';

type TGroupProps = {
  group: GroupDto;
};

const GroupItem = ({ group: { id, name, city, members, maxPlayerCount } }: TGroupProps) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const getRandomImg = () => {
    const imgArray = [Hamburger, Cupcake, StreetFood, Breakfast404];
    return imgArray[Math.floor(Math.random() * imgArray.length)];
  };

  return (
    <Link to={`${pathname}/${id}`}>
      <Box pos='relative'>
        <HStack
          as='button'
          pos='relative'
          w={{ base: '90vw', md: '50vw', lg: '33vw', xl: '25vw' }}
          rounded='2xl'
          boxShadow='0 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 6px 1px rgba(0, 0, 0, 0.1)'
          my='4'
          p='2'
          _hover={{ cursor: 'pointer' }}
        >
          <Image src={getRandomImg()} alt='Hamburger' w='30%' p='2' />
          <Box textAlign='left' w='70%'>
            <Text fontSize='lg' fontWeight='600' wordBreak='break-word' isTruncated>
              {name.toLocaleUpperCase()}
            </Text>
            <Text fontSize='md' isTruncated>
              {members?.length} / {maxPlayerCount} {t('Players')} - {city.split(',')[0]}
            </Text>
          </Box>
        </HStack>
      </Box>
    </Link>
  );
};

export default GroupItem;
