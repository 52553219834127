import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'lib/react-query';
import { getBackendAPI, RestaurantControllerSearchByKeywordParams } from 'proxy';

function searchRestaurant(request: RestaurantControllerSearchByKeywordParams) {
  return getBackendAPI().restaurantControllerSearchByKeyword(request);
}

export function useSearchRestaurant(request: RestaurantControllerSearchByKeywordParams) {
  return useQuery(queryKeys.restaurants.search(request), async () => searchRestaurant(request));
}
