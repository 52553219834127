import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Button,
  VStack,
  Text,
  SimpleGrid,
  Icon,
  Heading,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Image,
} from '@chakra-ui/react';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RiShareLine,
  RiGroupLine,
  RiCommunityLine,
  RiAdminLine,
  RiFootballLine,
} from 'react-icons/ri';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { useStartGame } from 'api/game';
import { useGroupDetail } from 'api/group';
import { Chef } from 'assets/svg';
import NextStoreCard from 'components/Game/NextStoreCard';
import StoreCard from 'components/Game/StoreCard';
import EditGroupModal from 'components/Group/EditGroupModal';
import LoadingSpinner from 'components/LoadingSpinner';
import { queryClient, queryKeys } from 'lib/react-query';
import { GameDto, GameRoundDto } from 'proxy';

import AddStore from '../../components/Game/AddStore';
import CreateGame from '../../components/Game/CreateGame';
import GoBack from '../../components/Navigation/GoBack';
import { groupState } from '../../state/atom';

type TParams = {
  groupId: string;
};

type TLocalGroup = {
  userHasStore: boolean;
  restaurantSize: number;
  gameReady: boolean;
  currentGame: GameDto | null;
  currentRound: GameRoundDto | null;
};

const GroupDetails = () => {
  const [localGroup, setLocalGroup] = useState<TLocalGroup>({
    userHasStore: false,
    restaurantSize: 0,
    gameReady: false,
    currentGame: null,
    currentRound: null,
  });
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const { groupId } = useParams<TParams>();
  const { user } = useAuth0();

  const [globalGroupState, setGroupState] = useRecoilState(groupState);

  const { isLoading, data } = useGroupDetail(groupId);

  const startGameMutation = useStartGame();

  useEffect(() => {
    if (groupId) {
      if (data?.currentGame) {
        const gameId = data?.currentGame?.id === undefined ? '' : data?.currentGame?.id;
        const roundId =
          data?.currentGame?.currentRound?.id === undefined
            ? ''
            : data?.currentGame?.currentRound?.id;
        if (data?.currentGame && gameId && roundId) {
          setGroupState({
            groupId: groupId,
            gameId: gameId,
            roundId: roundId,
          });
        } else if (data?.currentGame && gameId) {
          setGroupState({
            ...globalGroupState,
            groupId: groupId,
            gameId: gameId,
          });
        } else {
          setGroupState({
            ...globalGroupState,
            groupId: groupId,
          });
        }
      }
    }
    if (data?.currentGame) {
      const userHasStoreValue =
        data?.currentGame?.restaurants &&
        user?.sub &&
        Object.keys(data?.currentGame?.restaurants).includes(user?.sub);
      const restaurantSizeValue =
        data?.currentGame?.restaurants && Object.keys(data?.currentGame?.restaurants).length;
      const gameReadyValue = restaurantSizeValue === data?.maxPlayerCount;
      const currentGameValue = data?.currentGame;
      const currentRoundValue = data?.currentGame?.currentRound ?? null;
      setLocalGroup({
        userHasStore: !!userHasStoreValue,
        restaurantSize: restaurantSizeValue,
        gameReady: gameReadyValue,
        currentGame: currentGameValue,
        currentRound: currentRoundValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleInvite = () => {
    history.push(`${pathname}/invite/${data?.inviteCode}`);
  };

  const startGame = () => {
    startGameMutation.mutate(
      { groupId, gameId: globalGroupState.gameId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKeys.groups.detail(groupId));
        },
      }
    );
  };

  const handleGroupDetailsRender = () => {
    if (data) {
      const gameStatus = data?.currentGame;
      if (gameStatus === undefined) {
        return <CreateGame group={data} />;
      }
      if (localGroup?.currentRound?.restaurant && localGroup?.currentGame?.status === 'RUNNING') {
        return (
          <VStack>
            <NextStoreCard game={localGroup?.currentGame} />
            {data?.currentGame?.previousRound
              ?.sort((a, b) => Date.parse(b?.meetingTime ?? '') - Date.parse(a?.meetingTime ?? ''))
              .map((round) => (
                <StoreCard
                  key={round?.id}
                  name={round?.restaurant?.name}
                  date={round?.meetingTime}
                  location={round?.restaurant?.address}
                />
              ))}
          </VStack>
        );
      } else {
        return (
          <>
            {localGroup.gameReady ? (
              <VStack>
                <Image src={Chef} w={{ base: '66vw', lg: '33vw', xl: '23vw' }} alt='Cook/ Chef' />
                <Button
                  color='white'
                  bgColor='brandorange.300'
                  _hover={{ bgColor: 'brandorange.400' }}
                  _active={{ bgColor: 'brandorange.400' }}
                  w={{ base: '90vw', lg: '33vw', xl: '25vw' }}
                  onClick={startGame}
                >
                  {t('Start game')}
                </Button>
              </VStack>
            ) : (
              <AddStore userHasStore={localGroup?.userHasStore} groupCity={data?.city} />
            )}
          </>
        );
      }
    } else {
      return '';
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Fragment>
      {data && (
        <VStack>
          <GoBack title={data?.name ?? ''} />
          <Box>
            <VStack>
              {!data?.currentGame && <EditGroupModal group={data} />}
              <SimpleGrid w={{ base: '90vw', lg: '33vw', xl: '25vw' }} columns={2} spacing={2}>
                <VStack rounded='2xl' padding={2} border='1px solid' borderColor='brandgreen.700'>
                  <Icon
                    color='brandgreen.700'
                    as={RiAdminLine}
                    pos='relative'
                    top='0.125em'
                    boxSize={6}
                  />
                  <Text fontWeight='600' wordBreak='break-word'>
                    {data?.admin ?? 'Admin'}
                  </Text>
                </VStack>
                <VStack rounded='2xl' padding={2} border='1px solid' borderColor='brandblue.700'>
                  <Icon
                    color='brandblue.700'
                    as={RiGroupLine}
                    pos='relative'
                    top='0.125em'
                    boxSize={6}
                  />
                  <Text fontWeight='600'>
                    {data?.members?.length} / {data?.maxPlayerCount}
                  </Text>
                </VStack>
                <VStack rounded='2xl' padding={2} border='1px solid' borderColor='brandblack.700'>
                  <Icon
                    color='brandblack.700'
                    as={RiCommunityLine}
                    pos='relative'
                    top='0.125em'
                    boxSize={6}
                  />
                  <Text fontWeight='600'>{data?.city?.split(',')[0]}</Text>
                </VStack>
                <VStack rounded='2xl' padding={2} border='1px solid' borderColor='brandorange.300'>
                  <Icon
                    color='brandorange.300'
                    as={RiFootballLine}
                    pos='relative'
                    top='0.125em'
                    boxSize={6}
                  />
                  <Text fontWeight='600' isTruncated>
                    {`${t('Games played')}:
                      ${data?.previousGames?.length ? data?.previousGames?.length : 0}
                    `}
                  </Text>
                </VStack>
              </SimpleGrid>
              {!data?.currentGame && (
                <Button
                  colorScheme='brandgreen'
                  bgColor='brandgreen.50'
                  variant='outline'
                  leftIcon={<RiShareLine fontSize='1.5rem' />}
                  aria-label='Invite to group'
                  onClick={handleInvite}
                  rounded='2xl'
                  w={{ base: '90vw', lg: '33vw', xl: '25vw' }}
                >
                  <Text>{t('Invite players')}</Text>
                </Button>
              )}
            </VStack>
          </Box>
          {data?.currentGame && (
            <Heading as='h4' size='2xl' mb='4'>
              {t('Game')} {data?.previousGames?.length ? data?.previousGames?.length + 1 : 1}
            </Heading>
          )}
          {data?.currentGame && localGroup?.restaurantSize < data?.maxPlayerCount && (
            <Alert status='info' w={{ base: '90vw', lg: '33vw', xl: '25vw' }} rounded='2xl'>
              <AlertIcon />
              <Box flex='1'>
                <AlertTitle>{t('Missing selections title')}</AlertTitle>
                <AlertDescription display='block'>
                  {t('Missing selections description')}{' '}
                  {`${localGroup?.restaurantSize} / ${data?.maxPlayerCount}`}
                </AlertDescription>
              </Box>
            </Alert>
          )}
          <Box paddingBottom='16'>{handleGroupDetailsRender()}</Box>
        </VStack>
      )}
    </Fragment>
  );
};

export default GroupDetails;
