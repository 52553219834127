import { Text, Heading, Image, VStack, Button } from '@chakra-ui/react';
import { RefetchOptions, QueryObserverResult } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';

import { Server } from 'assets/svg';

type TErrorProps = {
  refetch?: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<unknown, unknown>>;
  onRefetch?: () => void;
};

const Errors = ({ refetch, onRefetch }: TErrorProps) => {
  const history = useHistory();
  const tryAgain = () => {
    refetch && refetch();
    onRefetch && onRefetch();
  };

  return (
    <VStack h='80%' mb='8'>
      <Image src={Server} h='50%' alt='Error' />
      <Heading as='h1' size='4xl' color='red.500'>
        Server Error
      </Heading>
      <Heading as='h2' size='xl'>
        No connection to server
      </Heading>
      <Text>Please try again later.</Text>
      <Button bg='brandorange.300' color='white' onClick={tryAgain}>
        Try again
      </Button>
      <Text>or</Text>
      <Button variant='outline' colorScheme='brandgreen' onClick={() => history.push('/')}>
        Return Home
      </Button>
    </VStack>
  );
};

export default Errors;
