import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Heading,
  List,
  ListItem,
  ListIcon,
  Button,
  Center,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Icon,
  Alert,
  AlertIcon,
  VStack,
  Link,
} from '@chakra-ui/react';
// eslint-disable-next-line import/no-duplicates
import { formatDistanceToNow, addMinutes } from 'date-fns';
import { isPast } from 'date-fns/esm';
// eslint-disable-next-line import/no-duplicates
import { de, enGB, enUS } from 'date-fns/locale';
import { useEffect, useState } from 'react';
import { isIOS, isAndroid } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { RiArrowDownSLine, RiCalendarEventLine, RiLinksFill, RiMapPinLine } from 'react-icons/ri';
import { useRecoilValue } from 'recoil';

import { useStartRating } from 'api/round';
import { queryClient, queryKeys } from 'lib/react-query';
import { GameDto } from 'proxy';
import { groupState, userState } from 'state/atom';

import ChangeDate from './ChangeDate';
import RatingModal from './Rating/RatingModal';

type TProps = {
  game: GameDto;
};

const NextStoreCard = ({ game }: TProps) => {
  const { t } = useTranslation();
  const { user } = useAuth0();
  const { groupId, gameId } = useRecoilValue(groupState);
  const [formattedDate, setFormattedDate] = useState<Date | null>(null);
  const { userLanguage } = useRecoilValue(userState);
  const dateOptions: any = { dateStyle: 'short', timeStyle: 'short' };

  useEffect(() => {
    if (game?.currentRound?.meetingTime) {
      const getOffset = new Date(game?.currentRound?.meetingTime).getTimezoneOffset();

      setFormattedDate(addMinutes(new Date(game?.currentRound?.meetingTime), getOffset));
    } else {
      setFormattedDate(null);
    }
  }, [game?.currentRound?.meetingTime]);

  const startRatingMutation = useStartRating();

  const startRating = () => {
    startRatingMutation.mutate(
      {
        groupId,
        gameId,
        roundId: game?.currentRound?.id ?? '',
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKeys.groups.detail(groupId));
        },
      }
    );
  };

  const getLocale = () => {
    if (userLanguage.includes('de')) {
      return de;
    } else if (userLanguage.includes('GB')) {
      return enGB;
    } else {
      return enUS;
    }
  };

  const getDateLocale = () => {
    if (userLanguage.includes('de')) {
      return 'de-DE';
    } else if (userLanguage.includes('GB')) {
      return 'en-GB';
    } else {
      return 'en-US';
    }
  };

  const renderButton = () => {
    let timeToRate;
    if (formattedDate) {
      timeToRate = formatDistanceToNow(formattedDate, {
        locale: getLocale(),
        includeSeconds: true,
        addSuffix: true,
      });
    }
    if (game?.currentRound?.owner === user?.sub && formattedDate && isPast(formattedDate)) {
      return (
        <Button
          bgColor='white'
          colorScheme='brandgreen'
          variant='outline'
          w={{ base: '90vw', lg: '33vw', xl: '15vw' }}
          onClick={startRating}
          disabled={!game?.currentRound?.meetingTime}
        >
          {t('Unlock rating')}
        </Button>
      );
    } else {
      return (
        <Text>
          {formattedDate && isPast(formattedDate) ? (
            <Alert status='info' rounded='2xl'>
              <AlertIcon />
              {t('User with selection must unlock rating')}
            </Alert>
          ) : (
            timeToRate
          )}
        </Text>
      );
    }
  };

  const shareMeetingLocation = async () => {
    if (isAndroid || isIOS) {
      try {
        await navigator.share({
          title: 'gourmetgames.app',
          text: t('Share meeting location'),
          url: `${game?.currentRound?.restaurant?.url}`,
        });
      } catch (err) {
        console.error('Error: ' + err);
      }
    } else {
      window.open(game?.currentRound?.restaurant?.url);
    }
  };

  return (
    <Box
      rounded='2xl'
      boxShadow='0 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 6px 1px rgba(0, 0, 0, 0.1)'
      w={{ base: '90vw', lg: '33vw', xl: '25vw' }}
      p='5'
      bg='brandbeige.100'
      color='brandblue.700'
      borderColor='brandblue.700'
      border='4px solid'
      pos='relative'
    >
      <Heading as='h4' size='lg' isTruncated mb='4'>
        {game?.currentRound?.restaurant?.name}
      </Heading>
      <Menu placement='bottom'>
        <MenuButton
          as={Button}
          bgColor='transparent'
          pos='absolute'
          top='0'
          right='0'
          _hover={{ bgColor: 'brandorange.300' }}
          _active={{ bgColor: 'brandorange.300' }}
          rounded='xl'
        >
          <Icon as={RiArrowDownSLine} fontSize='2rem' />
        </MenuButton>
        <MenuList>
          {user?.sub === game?.currentRound?.owner && (
            <ChangeDate meetingTime={game?.currentRound?.meetingTime} />
          )}
          <MenuItem onClick={shareMeetingLocation}>{t('Share')}</MenuItem>
        </MenuList>
      </Menu>
      <List spacing={3} color='brandblue.700' fontWeight='600'>
        <ListItem>
          <ListIcon
            pos='relative'
            top='0.125em'
            as={RiCalendarEventLine}
            boxSize={6}
            color='brandorange.300'
          />
          {game?.currentRound?.meetingTime
            ? `${new Intl.DateTimeFormat(getDateLocale(), dateOptions).format(
                formattedDate ?? Date.now()
              )}`
            : user?.sub === game?.currentRound?.owner
            ? t('No date')
            : t('Date pending')}
        </ListItem>
        {game?.currentRound?.restaurant?.address ? (
          <ListItem>
            <ListIcon
              pos='relative'
              top='0.125em'
              as={RiMapPinLine}
              boxSize={6}
              color='brandorange.300'
            />
            {game?.currentRound?.restaurant?.address}
          </ListItem>
        ) : (
          <ListItem>
            <ListIcon
              pos='relative'
              top='0.125em'
              as={RiLinksFill}
              boxSize={6}
              color='brandorange.300'
            />
            <Link href={game.currentRound?.restaurant.url} target='_blank' rel='noreferrer'>
              {game.currentRound?.restaurant.url}
            </Link>
          </ListItem>
        )}
      </List>
      <Center mt='4'>
        {game?.currentRound?.status === 'IN_REVIEW' ? (
          <VStack>
            <Text>
              {game?.currentRound?.ratings.length} / {Object.keys(game?.restaurants).length - 1}
            </Text>
            <RatingModal
              name={game?.currentRound?.restaurant?.name}
              owner={game?.currentRound?.owner}
              ratings={game?.currentRound?.ratings}
            />
          </VStack>
        ) : (
          <>{renderButton()}</>
        )}
      </Center>
    </Box>
  );
};

export default NextStoreCard;
