import { useMutation } from '@tanstack/react-query';

import { getBackendAPI } from 'proxy';

function startRating(request: { groupId: string; gameId: string; roundId: string }) {
  return getBackendAPI().roundControllerStartRating(
    request.groupId,
    request.gameId,
    request.roundId
  );
}

export function useStartRating() {
  return useMutation(startRating);
}
