import {
  VStack,
  Heading,
  ListItem,
  List,
  Box,
  Skeleton,
  IconButton,
  HStack,
  Text,
  Image,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { HiRefresh } from 'react-icons/hi';

import { useGroups } from 'api/group';
import { NoGroup } from 'assets/svg';
import Errors from 'components/Errors';
import { queryClient, queryKeys } from 'lib/react-query';

import GroupItem from '../../components/Group/GroupItem';

const Groups = () => {
  const { isLoading, data, error } = useGroups();

  const { t } = useTranslation();

  if (error) {
    return <Errors onRefetch={() => queryClient.invalidateQueries(queryKeys.groups.lists())} />;
  }

  return (
    <VStack>
      <HStack w={{ base: '90vw', lg: '33vw', xl: '25vw' }}>
        <Heading as='h4' size='lg' textAlign='left' w={{ base: '90vw', lg: '33vw', xl: '25vw' }}>
          {t('Your groups')}
        </Heading>
        <IconButton
          aria-label='Refetch groups'
          icon={<HiRefresh fontSize='24' />}
          onClick={() => queryClient.invalidateQueries(queryKeys.groups.lists())}
        />
      </HStack>
      <Box>
        {data?.length === 0 ? (
          <VStack>
            <Image
              src={NoGroup}
              w={{ base: '66vw', sm: '33vw', lg: '20vw', xl: '23vw' }}
              alt='No groups'
            />
            <Heading>{t('No groups')}</Heading>
            <Text align='center' wordBreak='break-word'>
              {t('Join or create a group.')}
            </Text>
          </VStack>
        ) : (
          <List spacing='6' mb='16'>
            {data?.map((group) => (
              <Skeleton key={group?.id} isLoaded={!isLoading} rounded='2xl'>
                <ListItem>
                  <GroupItem group={group} />
                </ListItem>
              </Skeleton>
            ))}
          </List>
        )}
      </Box>
    </VStack>
  );
};

export default Groups;
