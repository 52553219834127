import { selector } from 'recoil';

import { ratingState } from './atom';

export const ratingTotalState = selector({
  key: 'ratingTotalState',
  get: ({ get }) => {
    const { rating1, rating2, rating3, rating4 } = get(ratingState);
    const calcRating = (rating1 + rating2 + rating3 + rating4) / 4;

    return calcRating.toFixed(2);
  },
});
