import { useMutation } from '@tanstack/react-query';

import { getBackendAPI, GroupRequest } from 'proxy';

function editGroup(request: { groupId: string; group: GroupRequest }) {
  return getBackendAPI().groupControllerEditGroup(request.groupId, request.group);
}

export function useEditGroup() {
  return useMutation(editGroup);
}
