import { useMutation } from '@tanstack/react-query';

import { getBackendAPI } from 'proxy';

function updateMeetingTime(request: {
  groupId: string;
  gameId: string;
  roundId: string;
  meetingTime: string;
}) {
  return getBackendAPI().roundControllerUpdateMeetingTime(
    request.groupId,
    request.gameId,
    request.roundId,
    { meetingTime: request.meetingTime }
  );
}

export function useUpdateMeetingTime() {
  return useMutation(updateMeetingTime);
}
