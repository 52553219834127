import { useMutation } from '@tanstack/react-query';

import { getBackendAPI, RestaurantRequest } from 'proxy';

function chooseRestaurant(request: {
  groupId: string;
  gameId: string;
  restaurant: RestaurantRequest;
}) {
  return getBackendAPI().gameControllerChooseRestaurant(
    request.groupId,
    request.gameId,
    request.restaurant
  );
}

export function useChooseRestaurant() {
  return useMutation(chooseRestaurant);
}
