import { VStack, ListItem, OrderedList, Text, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Reviews } from 'assets/svg';

const Rating0 = () => {
  const { t } = useTranslation();
  return (
    <VStack align='start' p='8'>
      <Image src={Reviews} w={{ base: '66vw', lg: '33vw', xl: '23vw' }} alt='Reviews' />
      <Text>{t('ExplainText')}</Text>
      <Text>{t('ExplainTextListHeading')}</Text>
      <OrderedList fontWeight='bold'>
        <ListItem>{t('Location')}</ListItem>
        <ListItem>{t('Service')}</ListItem>
        <ListItem>{t('PricePerformance')}</ListItem>
        <ListItem>{t('QualityTaste')}</ListItem>
      </OrderedList>
      <Text>{t('RatingScaleText')}</Text>
      <Text fontWeight='semibold'>{t('RatingScaleTextNumbers')}</Text>
    </VStack>
  );
};

export default Rating0;
